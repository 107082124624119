<h2 mat-dialog-title class="d-flex justify-content-center align-item-center">
    <div class="col pt-4"> EWBs</div>
    <div class="d-flex justify-content-end col pt-4">
        <mat-icon (click)="close()">close</mat-icon>
    </div>
</h2>
<div mat-dialog-content *ngIf="errorList?.length > 0" class="mt15">
    <p class="subTitle">Total Failed EWBs Count: {{errorTotalRecord}}
    <a class="bg-transparent border-0 " (click)="download($event, 'EWB_EXPIRING_TODAY_GODOWN_YES')" >
        <mat-icon class="overflow-visible icon">get_app</mat-icon>
    </a>
        
    </p>
    <p class="note">If you are viewing this page between 12:00 AM and 4:00 PM, the details shown are for the previous day's failed EWB records.</p>
    <p class="note">If you are viewing this page after 4:00 PM, the details shown are for today's failed EWB records.</p>
    <div >
        <!-- <span [innerHTML]="data.message"></span> -->
        <div  class="table-responsive">
            <table  datatable class="table border hover" >
              <thead>
              <tr>              
                <th>Ewb No</th>
                <th>Trans ID</th>
                <th>Godown Name</th>
                <th>Error Message</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let obj of errorList">               
                <td>{{obj.ewbNo}}</td>
                <td>{{obj.transId}}</td>
                <td>{{obj.godownName}}</td>                
                <td>{{obj.errorDesc}}</td>                
              </tr>
            </tbody>
            </table>
          </div>
    </div>
    
    <div mat-dialog-actions>
        <div class="d-flex align-items-center">
        </div>
    </div>
</div>
<div mat-dialog-content *ngIf="errorList?.length === 0" class="mt15">
  <p>No Data found.</p>
</div>

<!-- 
<h1 mat-dialog-title>EWBs</h1>
<div mat-dialog-content>
    <p class="subTitle">{{data.subHeader}}
        <span><button class="bg-transparent border-0 justify-content-end" mat-button (click)="download($event, 'EWB_EXPIRING_TODAY_GODOWN_YES')" >
            <mat-icon class="overflow-visible icon">get_app</mat-icon>
            </button>
        </span>
    </p>
    <div mat-dialog-content>
        <span [innerHTML]="data.message"></span>
    </div>
</div>
<div mat-dialog-actions>
    <div class="d-flex align-items-center">

<button mat-raised-button color="primary" (click)="close()">close</button>
</div></div> -->