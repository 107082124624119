import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FasttagService {
  constructor(private http: HttpClient) { }

  fetchFastTagData(data:any) {
    return this.http.get(`${environment.apiUrl}/logilocker/fasttag?ewbId=${data.ewbId}&gstin=${data.gstin ? data.gstin : data.transId}`);
  }
}
