// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl:'',
  // apiUrl: 'https://2e93e177-a3cd-440d-986d-3eab554a71c0.mock.pstmn.io'
  apiUrl: 'https://dev.api.easywaybill.in/ezewb/v1',
  paymentURL: 'https://securegw-stage.paytm.in/order/process',
  mockURL: 'https://276ba087-7722-4809-9f7c-77535216df1a.mock.pstmn.io',
  GA_TRACKING_ID: 'G-7LSE3455LF',
  RECAPTCHA_V3_STACKBLITZ_KEY : '6LeHBK0bAAAAAOQVTvBOWhfb08cQfUpFoSE3FsmP',
  RECAPTCHA_V2_DUMMY_KEY :'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
