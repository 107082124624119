import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetsRoutingModule } from './widgets-routing.module';
import { WatchListCrudComponent } from './watch-list-crud/watch-list-crud.component';
import { MatIconModule } from '@angular/material/icon';
import { WatchListComponent } from './watch-list/watch-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GstinHeaderModule } from '../shared/components/gstin-header/gstin-header.module';
import { HeaderModule } from '../shared/components/header/header.module';
import { FooterBarModule } from '../shared/components/footer-bar/footer-bar.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GodownsComponent } from './godowns/godowns.component';
import { MatInputModule } from '@angular/material/input';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [WatchListCrudComponent, WatchListComponent, GodownsComponent],
  imports: [
    CommonModule,
    WidgetsRoutingModule,
    MatIconModule,
    FormsModule,
    GstinHeaderModule,
    HeaderModule,
    MatButtonModule,
    FooterBarModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatCardModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    HeaderModule,
    GstinHeaderModule,
    MatInputModule,
    InfiniteScrollModule
  ]
})
export class WidgetsModule { }
