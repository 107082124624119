<div class="container-fluid">
    <div class="text-center">

        <h3 mat-dialog-title>Help</h3>
    </div>
    <div mat-dialog-content class="card">
        <div class="embed-responsive embed-responsive-16by9">
            <video width="500" height="400" controls>
                <source src="https://ezwaybill-resources.s3.ap-south-1.amazonaws.com/AddIRISAsGSP.mp4" type="video/mp4">
            </video>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="onNoClick()">OK</button>
    </div>
</div>