import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private requestCount = 0;

  show() {
    this.requestCount++;
  }

  hide() {
    this.requestCount--;
  }

  isLoadingState() {
    return this.requestCount > 0;
  }
}