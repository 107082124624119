import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
@Injectable({
  providedIn: 'root'
})
export class TokenService {
  getItem: any;
  constructor() { }
  getter() {
    return localStorage.getItem('jwt');
  }

  getLoginToken() {

    const token = localStorage.getItem('logintoken');
    return token;
  }

  getOrganisations(): any[] | null {
    const org = localStorage.getItem('organisations');
    return org ? JSON.parse(org) : null;
  }
  setter(token: string, organisations: any, userid: any) {
    localStorage.setItem('logintoken', token);
    localStorage.setItem('userid', userid);
    localStorage.setItem('organisations', String(JSON.stringify(organisations)));
    return 1;
  }

  getCurrentRole() {
    const orgs = JSON.parse(localStorage.getItem('organisations') || 'null');
    const orgid = parseInt(localStorage.getItem('orgid') || 'null', 10);
    return orgs.find((org: any) => org.orgId === orgid).sysadmin;
  }

  getCurrentOrgName() {
    const orgs = JSON.parse(localStorage.getItem('organisations') || 'null');
    const orgid = parseInt(localStorage.getItem('orgid') || 'null', 10);
    return orgs.find((org: any) => org.orgId === orgid).orgname;
  }

  getCurrentOrganisationID() {
    const orgid = parseInt(localStorage.getItem('orgid') || 'null', 10);
    return orgid;
  }

  setJWT(token: string, sysadmin: number) {

    localStorage.setItem('jwt', token);
    localStorage.setItem('orgid', JSON.stringify(sysadmin));
  }
  destroy() {
    localStorage.clear();
    return true;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded: any = jwt_decode(token);

    if (decoded.exp === undefined) {
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getter() || undefined; // Assign `undefined` if `this.getter()` returns `null`
    }
    if (!token) { return true; }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) { return false; }
    return !(date.valueOf() > new Date().valueOf());
  }


  setActiveBusiness(gstin?: string, criteria?: any) {
    localStorage.setItem('activeGstin', gstin || '');
    localStorage.setItem('activeCriteria', JSON.stringify(criteria));
  }

  setGstinRole(role: any) {
    localStorage.setItem('gstinRole', role);
  }

  getGstinRole() {
    localStorage.getItem('gstinRole');
  }

  setSelectedGSTIN(gstin: any) {
    localStorage.setItem('selectedGSTIN', JSON.stringify(gstin));
  }
  getSelectedGSTIN() {
    return localStorage.getItem('selectedGSTIN');

  }
}
