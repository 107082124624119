import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MenuComponent } from './menu/menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(
private dialog : MatDialog
  ) { }
open(){
  const dialogRef = this.dialog.open(MenuComponent, {
    width: '80vw',
   });
dialogRef.afterClosed().subscribe(result => {

});
} 

}
