<div mat-dialog-content style="height: 250px;">
  <div *ngFor="let gstin of this.data.gstins" class="w-100">
    <div class="py-4 px-2" *ngIf="this.data.gstins.length > 0">
      <label class="cursor-pointer">
        <input class="px-3" type="radio" [(ngModel)]="selectedGSTIN" [value]="gstin"
          (change)="selectGstin(selectedGSTIN)">
        <span class="fed px-3" *ngIf="gstin.isOrganisation"> {{ selectedOrganisation?.orgname }} - ALL
          GSTINs/TransId</span>
        <span class="fed px-3" *ngIf="!gstin.isOrganisation"> {{ gstin.name }} - {{ gstin.gstn }}</span>
      </label>
    </div>
  </div>
</div>
<div mat-dialog-action class="p-3 border-top position-relative">
  <div class="position-absolute end-0">
    <button mat-button class="ld fw-bold" (click)="onCancel()">CANCEL</button>
    <button mat-button class="ld fw-bold" (click)="onOk()">OK</button>
  </div>
</div>