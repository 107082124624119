import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BusinessManagementService } from '../business-management.service';
import { WidgetsService } from 'src/app/widgets/widgets.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyGodownResolverService {
  constructor(
    private router: ActivatedRoute,
    private activeBusiness: BusinessManagementService,
    private WS: WidgetsService
  ) { }

  activeGstin = false;
  activeBus: any;
  type = null;


  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.activeBusiness.getActiveBusiness().subscribe((active) => {
      if (active === null) { this.activeGstin = false; } else { this.activeGstin = true; this.activeBus = active; }
    });
    //if (this.activeGstin) {
    //  return this.WS.fetchMyGodownList(this.activeBus.gstn);
   // }
  }
}
