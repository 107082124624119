<div class="container">
  <app-header></app-header>
  <app-gstin-header></app-gstin-header>
  <div class="row pageTitle">
    <span>  My WatchList</span>
  </div>
  <div
  class="scroll"
  style="height: 500px; overflow-y: scroll; padding-bottom: 35px;"
  infinite-scroll
  [scrollWindow]="false"
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollUpDistance]="scrollUpDistance"
  [infiniteScrollThrottle]="throttle"
  (scrolled)="doInfinite()"
>
  <div class="row" *ngFor="let data of datas">
    <div class="col">
      <div class="card cardBorder">
        <div class="card-title p-3 pb0">
          <div class="row d-flex justify-content-between">
            <div class="col" (click)=viewWatchList(data.id)>
              <div class="gwTitle" >{{ data.name }}</div>
            </div>
            <div class="col text-end" (click)=viewWatchList(data.id)>
              <div class="gwCount">{{ data.count }}</div>
            </div>
            <div class="col text-end text-primary">
              <a>
                <mat-icon class="clrBlue fs13" (click)=viewWatchList(data.id)>play_arrow</mat-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="card-body gwsubtitle pt5">
          <div class="row">
            <div class="" *ngIf="data.isDefault === 2">
              <div class="fs14">EWBs marked for watchlist from My EWB section</div>
            </div>
            <div class="">
              <span *ngIf="data.isDefault !== 2" class="fs13">{{ data.gstn }}</span>
            </div>
            <div class="">
              <div class="d-flex justify-content-end">
                <ng-container *ngIf="data.isDefault !== 2">
                  <button class="border-0 bg-transparent "
                    (click)="editWatchList(data)"><mat-icon class="clrBlue">edit</mat-icon></button>
                  <button class="border-0 bg-transparent "
                    (click)="deleteAlert(data)"><mat-icon  class="clrBlue">delete</mat-icon></button>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
  *ngIf="(loading | async) && scrolldisplay"
  class="m-4 p-4 d-flex flex-column justify-content-center align-items-center"
  style="flex-direction: column">
      <div class="">
          <img src="/assets/icons/Spinner-5.gif" alt="Spinner" />
      </div>
      <p class="m-0 p-0">Loading more data...</p>
  </div>
</div>

  <div class="row align-items-center" *ngIf="datas?.length === 0">
    <div class="col-12">
      <div class="text-center">
        <h4>
          Click on the <mat-icon>add</mat-icon> to create your watchlist
        </h4>
      </div>
    </div>
  </div>
  <button class="addBn posit" mat-fab  (click)="addWatchList()"><mat-icon
      class="m-0 p-0 text-white">add</mat-icon></button>
  <app-footer-bar></app-footer-bar>
</div>
<router-outlet></router-outlet>