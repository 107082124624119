import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToasterService } from '../shared/services/toaster.service';

@Component({
  selector: 'app-generate-ewb-error-modal',
  templateUrl: './generate-ewb-error-modal.component.html',
  styleUrls: ['./generate-ewb-error-modal.component.scss']
})
export class GenerateEwbErrorModalComponent {
  constructor(
    private router: Router,
    private toaster: ToasterService,
    public dialogRef: MatDialogRef<GenerateEwbErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  
  close(): void {
    this.dialogRef.close();
  }
}
