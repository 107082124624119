import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { BusinessManagementService } from 'src/app/shared/services/business-management.service';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GetServiceService {
  activeGSTIN: any;
  defaultCriteria: any;

  constructor(private http: HttpClient, private BMS: BusinessManagementService) { }

  viewStats(data :any ,date:any): Observable<any> {
    let response;
    if(date){
      response = this.http.get(`${environment.apiUrl}/scheduler/stats?gstin=${data.gstn}&nature=${data.type}&date=${date}`);
    }else{
      response = this.http.get(`${environment.apiUrl}/scheduler/stats?gstin=${data.gstn}&nature=${data.type}`);
    }
    return response;
  }

  getStats(data:any, date:any): Observable<any> {
    let response;
    if (date) {
      response = this.http.get(`${environment.apiUrl}/scheduler/stats?gstin=${data.gstn}&nature=${data.type}&date=${date}`);
    } else {
      response = this.http.get(`${environment.apiUrl}/scheduler/stats?gstin=${data.gstn}&nature=${data.type} `);
    }
    return response;
  }
  getFailedEWBs(pageIndex:any): Observable<any> {
    this.BMS.getActiveBusiness().pipe( take(1),).subscribe((activ) => this.activeGSTIN = activ);
    let response;
      response = this.http.get(`${environment.apiUrl}/ewb/extnErrors?page=${pageIndex}&size=100&gstin=${this.activeGSTIN.gstn}`);
    
    return response;
  }
  // getFailedEWBs() {
  //   this.BMS.getActiveBusiness().pipe(
  //     take(1),
  //   ).subscribe((activ) => this.activeGSTIN = activ);

  //  let defaultCriteria = {
  //     "type": "EWB_EXPIRED_YESTERDAY",
  //     "defaultquery": this.activeGSTIN.defaultquery,
  //     "page": "0",
  //     "size": 20,
  //     "sortfield": "ewbDate",
  //     "sortdir": "desc",
  //     "addlquery": {
  //       "operator": "and",
  //       "criterias": [
  //         {
  //           "p": "godownId",
  //           "o": "neq",
  //           "v": "-1"
  //         }
  //       ]
  //     }
  //   };
  //   // this.defaultCriteria = this.activeGSTIN.defaultquery;

  //   const ewbList = this.http.post(`${environment.apiUrl}/ewb/search?gstin=${this.activeGSTIN.gstn}`,
  //   defaultCriteria);
  //   const count = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${this.activeGSTIN.gstn}`,
  //   defaultCriteria);
  //   console.trace();

  //   return forkJoin([ewbList, count]);
  // }
}
