import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-gstin-video',
  templateUrl: './edit-gstin-video.component.html',
  styleUrls: ['./edit-gstin-video.component.scss']
})
export class EditGstinVideoComponent {

}
