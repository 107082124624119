import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):any {
    if (!this.auth.isAuthenticated()) {
      if (!this.auth.isLoginAuthenticated()) {
        this.router.navigate(['/auth']);
      } else {
        this.auth.setLoginIndicator(1);
        this.router.navigate(['/select-organisation']);
      }
      return false;
    }

    const active = this.auth.subscriptionSource.value;
    if (active != null) {
      return true;
    } else {
      this.auth.setLoginIndicator(1);
      this.router.navigate(['/select-organisation']);
    }
  }
}
