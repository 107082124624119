<div class="container">
  <app-header></app-header>
  <app-gstin-header></app-gstin-header>
  <div class="row pageTitle">
    <span>My Godown</span>
  </div>
  <div class="row pb55" >
    <div *ngFor="let data of datas">
    <div class="card cardBorder" (click)="viewGodown(data.godownId)">
      <div class="card-body">
        <div class="row d-flex">
          <div class="col-sm-4 gdName">
            {{ data.godownName }}
          </div>
          <div class="col-sm-4 gdCount">
            {{ data.count }}
          </div>
          <div class="col-sm-4 ">
            <button class="bg-transparent border-0" mat-button (click)="download($event, data.godownName, data.godownId)" >
              <mat-icon class="overflow-visible icon">get_app</mat-icon>
              </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
<app-footer-bar></app-footer-bar>