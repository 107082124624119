import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyGodownResolverService } from '../shared/services/resolver/my-godown-resolver.service';
import { GodownsComponent } from './godowns/godowns.component';
import { WatchListComponent } from './watch-list/watch-list.component';
import { WatchListCrudComponent } from './watch-list-crud/watch-list-crud.component';

const routes: Routes = [
  {
    path: 'godowns',
    resolve: { data: MyGodownResolverService },
    component: GodownsComponent
  },
  {
    path: 'watch-list-crud/:id', // Move this route below the static route
    component: WatchListCrudComponent
  },
  {
    path: 'watch-list-crud',
    component: WatchListCrudComponent
  },
  {
    path: 'watch-list',
    component: WatchListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WidgetsRoutingModule { }
