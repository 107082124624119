import { Component, OnInit, Input, Inject } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { BusinessManagementService } from "../../services/business-management.service";
import { ViewEwayService } from "../../services/view-eway.service";
import { ActionsService } from "../../services/actions.service";
import { catchError, mergeMap, timestamp, map } from "rxjs/operators";
import * as moment from 'moment';
import { from } from "rxjs";
import { take } from 'rxjs/operators';
import { ToasterService } from "../../services/toaster.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../../services/auth.service";
import { WidgetsService } from "src/app/widgets/widgets.service";
import { BaseResponse } from "src/app/_models/Response";
import { EwbDetailsService } from "../../services/ewb-details.service";
import { CommonService } from "../../services/common/common.service";

@Component({
  selector: 'app-extent-validity-cewb-modal',
  templateUrl: './extent-validity-cewb-modal.component.html',
  styleUrls: ['./extent-validity-cewb-modal.component.scss']
})
export class ExtentValidityCewbModalComponent {
  extendEWBForm: any;
  stateList: any;
  currentProgress = 0;
  viewSegment = "failure";
  successEWBs: any = [];
  failedEWBs: any = [];
  subsctiption;
  gstin: any;
  isFastTagDataFetched = false
   vehicleNo!: string;
   selectedCount!: number;
   state!: string;
   selectedSearch: any;
   type: any;
   activeGstin: any;
   totalCount: any;
   toPincode: any;
   selectedItems: any;
   business$: any;
   loginInd: any;
  ShowDisclaimer: boolean = false;
  // @Input() totalEwbs: any;
  totalEwbs: any;
  godownList: any;
  currentDate = new Date();
  ewbDetail: any;
  expFastagDetails: any;
  fastagInfo:any;
  fastagInfoLoaded: boolean = false;

  constructor(
    private fb: FormBuilder,
    private businessManagementService: BusinessManagementService,
    private actionsService: ActionsService,
    private toaster: ToasterService,
    public dialogRef: MatDialogRef<ExtentValidityCewbModalComponent>,
    private auth: AuthService,
    private WS: WidgetsService,
    private ewbDetailService: EwbDetailsService,
    private common: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.subsctiption = this.businessManagementService.activeBusiness.subscribe((res) => {
      this.gstin = res.gstn;
    });
    this.extendDataInit();
   
  }
  getData(){
    this.vehicleNo = this.data.vehicleNo;
    this.type = this.data.type;
    this.selectedItems = this.data.selectedItems;
    this.selectedCount = this.data.selectedCount;
    this.state = this.data.state;
    this.selectedSearch = this.data.selectedSearch;
    this.activeGstin = this.data.activeGstin;
    this.totalEwbs = this.data.totalEwbs;
    this.totalCount = this.data.totalCount;
    //this.toPincode = this.data.toPincode;
  }

  ngOnInit() {
    this.getData();
    this.fetchFsData();
   
    this.stateList = this.businessManagementService.stateListData();
    this.selectedCount = this.totalCount;
   
    this.extendEWBForm = this.fb.group({
      fromPlace: [
        "",
        [Validators.required, Validators.pattern("^[a-zA-Z ]*$")],
      ],
      fromState: ["", Validators.required],
      fromPincode: [
        "",
        [Validators.required, Validators.pattern("^[1-9][0-9]{5}$")],
      ],
      // toPincode: [
      //   "",
      //   [Validators.required, Validators.pattern("^[1-9][0-9]{5}$")],
      // ],
      remainingDistance: [null],
      consignmentStatus: ["M"],
      extnRsnCode: ["99"],
      extnRemarks: ["Extend"],
      transMode: [null],
      vehicleNo: [""],
      transDocNo: [null],
      transDocDate: [null],
      transitType: [""],
      addressLine1: [null],
      addressLine2: [null],
      addressLine3: [null],
    });
    this.business$ = this.businessManagementService.getActiveBusiness().subscribe((active) => {
      this.auth.getLoginIndicator().pipe(take(1)).subscribe((ind) => this.loginInd = ind);
      if (active === null && this.loginInd === 2) {
        this.toaster.warning('No GSTIN selected');
      } else if (active != null && this.loginInd === 2) {
        this.WS.godownEWBCount(active.gstn).subscribe((response: any) => {
          if (response.status === 1) {
            this.WS.fetchMyGodownList(active.gstn).subscribe((response: BaseResponse) => {
              if (response.status === 1) {
                this.godownList = response.response;
              }
            }, (e) => {

            });
          }
        }, (e) => {

        });
      }
     
    });
  }
 
  extendDataInit() {
    this.stateList = this.businessManagementService.stateListData();
    if (this.ewbDetail) {
      const transDate = this.ewbDetail.transDocDate ? this.ewbDetail.transDocDate.split("/") : null;
      this.extendEWBForm = this.fb.group({
        fromPlace: [null, [Validators.required, Validators.pattern("^[a-zA-Z ]*$")], ],
        fromState: [null, Validators.required],
        fromPincode: [null, [Validators.required, Validators.pattern("^[1-9][0-9]{5}$")], ],
        remainingDistance: [null],
        consignmentStatus: ["M"],
        extnRsnCode: ["99"],
        extnRemarks: ["Extend"],
        transMode: [this.ewbDetail.transMode],
        vehicleNo: [this.ewbDetail.vehicleNo ? this.ewbDetail.vehicleNo : null],
        transDocNo: [this.ewbDetail.transDocNo ? this.ewbDetail.transDocNo : null,],
        transDocDate: [ this.ewbDetail.transDocDate ? new Date(transDate[2], +transDate[1] - 1, transDate[0]): null,],
        ewbId: [`${this.ewbDetail.ewbId}`],
        ewbNo: [`${this.ewbDetail.ewbNo}`],
        transitType: [null, Validators.required],
        addressLine1: [null, Validators.required],
        addressLine2: [null, Validators.required],
        addressLine3: [null, Validators.required],
      });
    }
  }
  get consignmentStatus() {
    return this.extendEWBForm.get("consignmentStatus") as FormControl;
  }

  get transDocNo() {
    return this.extendEWBForm.get("transDocNo") as FormControl;
  }
  get transMode() {
    return this.extendEWBForm.get("transMode") as FormControl;
  }
  get transDocDate() {
    return this.extendEWBForm.get("transDocDate") as FormControl;
  }


  checkInputVal() {
    const value = this.extendEWBForm.get("remainingDistance");
    if (
      this.extendEWBForm.value.remainingDistance <= 0 ||
      this.extendEWBForm.value.remainingDistance == null ||
      this.extendEWBForm.value.remainingDistance > 4000
    ) {
      value.setValue(null);
    }
  }
  onSetGodown(event: any) {
    this.godownList.forEach((e:any) => {
      if (event == e.godownId) {
        const baseExtendObject = Object.assign({}, this.extendEWBForm.value);
        baseExtendObject.addressLine1 = e.addressLine1;
        this.extendEWBForm.controls.addressLine1.setValue(e.addressLine1);
        baseExtendObject.addressLine2 = e.addressLine2;
        this.extendEWBForm.controls.addressLine2.setValue(e.addressLine2);
        baseExtendObject.addressLine3 = e.addressLine3;
        this.extendEWBForm.controls.addressLine3.setValue(e.addressLine3);
       
      }
    });
  }
  extendEWB() {
    if (this.extendEWBForm.valid) {
      this.state = "progress";
      let allEWBs = [];
      let successCount = 0;
      if (this.totalEwbs.length > 0) {
        for (const ewb of this.totalEwbs) {
          const baseExtendObject = Object.assign({}, this.extendEWBForm.value);
          // baseExtendObject.vehicleNo = ewb.hasOwnProperty("vehicleNo")
          //   ? ewb.vehicleNo
          //   : null;
          // baseExtendObject.transDocNo = ewb.hasOwnProperty("transDocNo")
          //   ? ewb.transDocNo
          //   : null;
          // baseExtendObject.transDocDate = ewb.hasOwnProperty("transDocDate")
          //   ? ewb.transDocDate.slice(0, 10)
          //   : null;
          baseExtendObject.ewbId = ewb.ewbId;
          baseExtendObject.ewbNo = ewb.ewbNo;
          baseExtendObject.userGstin = this.activeGstin.gstn;
          if (this.extendEWBForm.value['transMode'] != '1') {
            baseExtendObject.vehicleNo = null;
          }
          if (this.extendEWBForm.value['transitType'] == 'R'|| this.extendEWBForm.value['transitType'] == 'W' || this.extendEWBForm.value['transitType'] == 'O') {
            baseExtendObject.transMode = '5';
          }
          if (this.extendEWBForm.value.transDocDate) {
            baseExtendObject.transDocDate = moment(
              new Date(this.extendEWBForm.value.transDocDate)
            ).format('DD/MM/YYYY');
          }
          allEWBs.push(baseExtendObject);
        }
        from(allEWBs)
          .pipe(
            mergeMap(
              (extendPayload) =>
                this.actionsService.extendEWBBulk(
                  extendPayload,
                  this.activeGstin.gstn
                ),
              undefined,
              5
            ),
            timestamp(),
            map((stamp) => stamp.value)
          )
          .subscribe((resp) => {
            this.currentProgress += 1;
            if (resp.response.status === 1) {
              this.successEWBs.push(resp);
            } else {
              this.failedEWBs.push(resp);
            }
            if (
              this.successEWBs.length + this.failedEWBs.length ===
              this.selectedCount
            ) {
              this.state = "result";
            }
          });
      }
    }
  }

  segmentChanged($event: any) {
    this.viewSegment = this.viewSegment === "success" ? "failure" : "success";
  }
  getFastagInfo() {
    if (!this.gstin) {
      // this.toaster.warning('Please select GSTIN No.');
      return
    }
    if (this.isFastTagDataFetched) {
      return
    }
    this.businessManagementService.getFastagInfo(this.selectedItems[0], this.gstin).subscribe(
      (response: any) => {
        this.isFastTagDataFetched = true
        const stateNum = response.response.state;
        const matchingState = this.stateList.find((state: any) => state.key === stateNum);
        const state = matchingState ? matchingState.value : '';
        this.extendEWBForm.get('fromState').setValue(stateNum);
        this.extendEWBForm.patchValue({
          fromPlace: response.response.plazaName,
          fromPincode: response.response.pincode,
          remainingDistance: null,
          consignmentStatus: "M",
          extnRsnCode: "99",
          extnRemarks: "Extend",
          transMode: "1",
          vehicleNo: "",
          transDocNo: "",          
          transDocDate: "",
          transitType: "",
          addressLine1: null,
          addressLine2: null,
          addressLine3: null,
        });
        this.toaster.success('Successfully fetched data');
      }
    );
  }
  calculateDistance() {
    const fromPincode = this.extendEWBForm.value.fromPincode;
    //const toPincode = this.extendEWBForm.value.toPincode;
    if (!fromPincode) {
      this.toaster.warning('Pls ! enter a valid Current Pincode',);
      return;
    }
    this.actionsService.calculateDistance(fromPincode, '').subscribe(
      (res) => {
        if (res && res.status !== 0) {
          if (res.response >= 0) {
            this.extendEWBForm.patchValue({
              remainingDistance: res.response,
            });
          } else {
            this.toaster.warning('Pls input the remaining distance',);
          }
        } else {
          this.toaster.warning(res.message,);
        }
      },
      (err) => {
        this.toaster.warning(err);
      }
    );
  }
  extendEWBSubmit() {
    if (this.extendEWBForm.value.transDocDate) {
      this.extendEWBForm.value.transDocDate = moment(
        new Date(this.extendEWBForm.value.transDocDate)
      ).format('DD/MM/YYYY');
    }
    if (this.extendEWBForm.valid) {
      // 
      this.businessManagementService
        .getActiveBusiness()
        .pipe(take(1))
        .subscribe((active) => {
          this.extendEWBForm.value['userGstin'] = active.gstn
            ? active.gstn
            : this.data.selectedEWBs[0].transId;

          if (
            this.extendEWBForm.value['transMode'] != '1' &&
            this.extendEWBForm.value['vehicleNo'] != null
          ) {
            // this.extendEWBForm.value['vehicleNo'] = null;
            // this.bottomSheetRef.dismiss();
            if (this.extendEWBForm.value['vehicleNo'] != '') {
              this.toaster.warning(
                'Vehicle No cannot be passed if mode of transportation is through rail or air or ship',
              );
              return;
            }
          }
          if (this.extendEWBForm.value['transMode'] != '1') {
            this.extendEWBForm.value['vehicleNo'] = null;
          }
          this.actionsService
            .extendEWB(
              this.extendEWBForm.value,
              active.gstn ? active.gstn : this.data.selectedEWBs[0].transId
            )
            .subscribe(
              (response) => {
                if (response.status === 1) {
                  //this.bottomSheetRef.dismiss();
                  this.toaster.success('Validity Extended Successfully',);
                  this.extendEWBForm.reset();
                  this.actionsService.setActionDone(true);
                } else {
                  if (response.errorList.length !== 0) {
                    this.toaster.error(response.errorList[0].message);
                  } else {
                    this.toaster.warning(`${response.message}`,);
                  }
                }
              },
              (error) => {
                this.toaster.warning(error);
              }
            );
        });
    } else {
      this.toaster.warning('Invalid Inputs !',)
    }
  }
  fetchFsData(){
    console.log("fastag");
    this.ewbDetailService.fetchExpTdyFastag(this.data.totalEwbs[0].ewbNo, this.data.activeGstin.gstn).subscribe((response: BaseResponse) => {
      if (response) {
        this.common.setResponseData(null);
        if (response.status !== 0) {
          this.expFastagDetails = response.response;
          if(this.expFastagDetails !== null || this.expFastagDetails !== ''){
            this.common.setResponseData(this.expFastagDetails);
          }                 
          this.common.responseData.subscribe(fsdata =>{  
            this.fastagInfoLoaded = true;
            this.fastagInfo = fsdata;
            this.extendEWBForm.patchValue({
              fromPlace: this.fastagInfo?.city,
              fromState: this.fastagInfo?.state,
              fromPincode: this.fastagInfo?.pincode,
             
            });  
        
            });
         
        }else{
          this.common.responseData.subscribe(fsdata =>{  
            this.fastagInfo = fsdata;
            });
          this.toaster.warning("No FasTag information available.");
        }
      }
    });
  }
}
