import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
const users = JSON.parse(localStorage.getItem('users')|| "[]") ;
const godowns = JSON.parse(localStorage.getItem('godowns')|| "[]") ;
const gstins = JSON.parse(localStorage.getItem('gstins')|| "[]") ;
const invitedUser = JSON.parse(localStorage.getItem('invitedUser')|| "[]") ;
@Injectable({
    providedIn: 'root',
})
export class FakeBackendInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;
        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(delay(500))
            .pipe(dematerialize());

        function handleRoute() {

            switch (true) {
                case url.endsWith('/auth/register') && method === 'POST':
                    return register();
                // case url.endsWith('/auth/login') && method === 'POST':
                //     return authenticate();
                case url.endsWith('/auth/registration/complete') && method === 'POST':
                    return registerComplete();
                case url.match(/\/auth\/verify\?\S*\b/) && method === 'GET':
                    return verifyToken();
                case url.endsWith('/add-godown') && method === 'POST':
                    return addBusiness();
                case url.endsWith('/godowns') && method === 'GET':
                    return getGodowns();
                case url.endsWith('/add-gstin') && method === 'POST':
                    return addGstins();
                case url.endsWith('/gstins') && method === 'GET':
                    return getGstins();
                case url.endsWith('/invite-user') && method === 'POST':
                    return inviteUser();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }
        }

        // route functions

        function register() {
            const user = body;

            if (users.find((x :any) => x.email === user.email)) {
                return error('Email "' + user.email + '" is already taken');
            }

            user.id = users.length ? Math.max(...users.map((x :any) => x.id)) + 1 : 1;
            user.token = Math.random().toString(36).substring(7);
            users.push(user);
            localStorage.setItem('users', JSON.stringify(users));

            return ok(user);
        }

        function authenticate() {
            const { email, password } = body;
            const user = users.find((x :any) => x.email === email && x.password === password);
            // const user = users.find(x => x.email === email &&);
            if (!user) { return error('Email or password is incorrect'); }
            return ok({
                id: user.id,
                username: user.username,
                // firstName: user.firstName,
                // lastName: user.lastName,
                token: 'Bearer fake-jwt-token'
            });
        }

        function verifyToken() {
            const user = users.find((x :any)=> x.token === tokenFromUrl());
            if (!user) { return error('Invalid Token'); }
            return ok();
        }

        function registerComplete() {
            const { password, token } = body;
            const user = users.filter((x :any) => x.token === token)[0];
            const restUsers = users.filter((x :any) => x.token !== token);
            if (!user) { return error('Invalid Token or No Such User'); }
            user.password = password;
            restUsers.push(user);
            localStorage.setItem('users', JSON.stringify(users));
            return ok(user);
        }

        function addBusiness() {
            const godown = body;
            if (!isLoggedIn()) { return unauthorized(); }
            godowns.push(godown);
            localStorage.setItem('godowns', JSON.stringify(godowns));

            return ok(godown);
        }

        function getGodowns() {
            if (!isLoggedIn()) { return unauthorized(); }
            return ok(godowns);
        }

        function getGstins() {
            if (!isLoggedIn()) { return unauthorized(); }
            return ok(gstins);
        }

        function inviteUser() {
            const data = body;
            if (!isLoggedIn()) { return unauthorized(); }
            invitedUser.push(data);
            localStorage.setItem('invitedUser', JSON.stringify(invitedUser));

            return ok(data);
        }

        function addGstins() {
            const gstin = body;
            if (!isLoggedIn()) { return unauthorized(); }
            gstins.push(gstin);
            localStorage.setItem('gstins', JSON.stringify(gstins));

            return ok(gstin);
        }

        function ok(body ?: any) {
            return of(new HttpResponse({ status: 200, body }));
        }

        function unauthorized() {
            return throwError({ status: 401, error: { message: 'Unauthorised' } });
        }

        function error(message: string) {
            return throwError({ error: { message } });
        }

        function isLoggedIn() {
            return headers.get('Authorization') === 'Bearer fake-jwt-token';
        }

        function tokenFromUrl() {
            const urlParts = url.split('=');
            return urlParts[urlParts.length - 1];
        }
    }
}

export const fakeBackendProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};
