<div class="p-0 m-0 " *ngIf="isShowGstinHeader">
  <div class="pt-2  text-center d-flex justify-content-between selectOrg">
    <span class=" text-center d-flex align-items-center ">
      <button mat-icon-button color="primary" (click)="goBack()"><mat-icon style="height: 20px;">arrow_back</mat-icon></button>
      <span *ngIf="gstinName!=null" class="fw-bold">{{gstinName}}</span>
    </span>
    <span class="fRight d-flex align-items-center">
      <div (click)="opendilog()" class="cursor-pointer">
        <div>{{ selectedGSTIN }}<mat-icon class="py-2">keyboard_arrow_down</mat-icon></div>
      </div>
    </span>
  </div>
</div>