import { Component } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operators';
import { LoaderService } from './shared/services/loader.service';
import { BaseResponse } from './_models/Response';
import { BusinessManagementService } from './shared/services/business-management.service';
import { CacheService } from './shared/services/cache.service';
import { TokenService } from './shared/services/token.service';
import { ToasterService } from './shared/services/toaster.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentURLToken: any;

  constructor(
    private auth: AuthService,
    private router: Router,
    public loaderService: LoaderService,
    private bms: BusinessManagementService,
    private cache: CacheService,
    private tokenService: TokenService,
    private toaster: ToasterService
  ) {
    this.loaderService.show();
    this.addGAScript();
    const url = window.location;
    if (url.search !== "") {
      this.currentURLToken = new URLSearchParams(url.search).get("token");

      this.auth
        .initLoginAfterAd(this.currentURLToken)
        .subscribe((response: BaseResponse) => {

          localStorage.setItem('mfaEnabled', response.response.mfaEnabled)

          if (response.status === 1 && response.response.orgs.length > 1) {
            localStorage.setItem('userid', response.response.userId);
            this.tokenService.setter(response.response.token, response.response.orgs, response.response.userId);
            this.auth.setLoginIndicator(1);
            this.router.navigate(['/select-organisation']);
            this.toaster.success('Successfully Logged In !');
          } else if (response.status === 1 && response.response.orgs.length === 1) {
            this.tokenService.setter(response.response.token, response.response.orgs, response.response.userId);
            this.auth.loginComplete({ token: response.response.token, orgid: response.response.orgs[0].orgId }).subscribe(resp => {
              if (resp.status === 1) {
                this.tokenService.setJWT(resp.response.token, response.response.orgs[0].orgId);
                this.auth.setSubscription(resp.response.subscription);
                this.bms.setActiveBusiness(null);
                this.cache.reset();
                this.bms.getMyBusiness().subscribe(res => {
                  localStorage.setItem('businesses', JSON.stringify(res));
                  this.auth.setLoginIndicator(2);
                  if (res && res.length === 1) {
                    this.bms.setActiveBusiness(res[0]);
                    this.router.navigateByUrl('/dashboard');
                  } else {
                    this.router.navigateByUrl('/select-organisation');
                  }
                });
              }
            });
          } else {
            if (response.response.passwordexpired) {
              this.router.navigate([`/reset-password/${response.response.userId}`]);
            } else {
              this.toaster.warning(response.message);
            }
          }

        });
    }
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
      }
    });
    this.auth.getLoginIndicator().subscribe((value) => {
      if (value === 0) {
        this.showHead = false;
      } else if (value === 1) {
        this.showHead = false;
      } else if (value === 2) {
        this.showHead = true;
      }
    });
  }
  showHead = false;
  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
    document.head.prepend(gtagScript);
  }
}
