import { Injectable } from '@angular/core';
import { BusinessManagementService } from '../business-management.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GstinResolverService {
  constructor(
    private BMS: BusinessManagementService
  ) { }

  activeGstin = false;
  activeBus: any;

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.BMS.fetchGstinList();
  }
}
