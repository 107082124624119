import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WidgetsService {
  constructor(
    private http: HttpClient,
  ) { }

  fetchMyGodownList(gstn: any) {
    return this.http.get(`${environment.apiUrl}/godown/assignedGodowns?gstin=${gstn}`).pipe(map((response: any) => {
      return response;

    }));
  }

  fetchWatchList(gstin: string) {
    const header = {headers : new HttpHeaders({ 'Content-Type': 'application/json' })}
    return this.http.get(`${environment.apiUrl}/watchlist/get?gstin=${gstin}`, header).pipe(map((response) => {
      return response;
    }));
  }

  countWatchlist(activeGSTIN: any, data: any) {
    console.trace();
    const defaultCriteria = activeGSTIN.defaultquery;
    let array: any = [];
    if (data.length) {
      data.forEach((element: any) => {
        array.push(this.http.post(`${environment.apiUrl}/ewb/count?gstin=${activeGSTIN.gstn}`,
          { watchListId: element.id, defaultquery: defaultCriteria, page: 0, size: 10 }));
      });
    }
    return forkJoin(array);
  }

  getWatchListData(activeGSTIN: any, page: any) {
    const defaultCriteria = activeGSTIN.defaultquery;
    // return;
    return this.http.post(`${environment.apiUrl}/ewb/search?gstin=${activeGSTIN.gstn}`,
      { type: 'EWB_EXPIRING_TODAY', defaultquery: defaultCriteria, page: `${page}`, size: 10 }).pipe(map((response) => {
        return response;
      }));
  }

  addWatchList(data: any, active: any) {
    return this.http.post(`${environment.apiUrl}/watchlist/add?gstin=${active.gstn}`, data).pipe(map((response) => {
      return response;
    }));
  }
  editWatchList(data: any, gstn: any, id: any) {
    return this.http.post(`${environment.apiUrl}/watchlist/update?gstin=${gstn}&id=${id}`, data).pipe(map((response) => {
      return response;
    }));
  }
  godownEWBCount(gstn: any) {
    return this.http.get(`${environment.apiUrl}/godown/ewbCntWithName?gstin=${gstn}`).pipe(map((response) => {
      return response;
    }));
  }
  deleteWatchList(data: any) {
    return this.http.delete(`${environment.apiUrl}/watchlist/delete?gstin=${data.gstn}&wid=${data.id}`).pipe(map((response) => {
      return response;
    }));
  }
}
