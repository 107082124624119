
<div class="headerBg w-100">
        <span>
            <a routerLink="/dashboard" class="landingLogo">
                <img src="assets/icons/icon-192x192.png" alt="logo">
            </a>
        </span>       
        <span class=" text-primary menuIcon" (click)="open()">
            <mat-icon class="clrAqua">view_headline</mat-icon>
        </span>
      
</div>

