<div class="container">
    <div class="row">
        <div class="col">
            <h3 class="text-center">Help</h3>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/yGdA7JDKLYI"
                        frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer fixed-bottom bg-light">
    <div class="container">
        <div class="row justify-content-end">
            <div class="col-auto">
                <button class="btn btn-primary" onclick="dismissModal()">Close</button>
            </div>
        </div>
    </div>
</footer>