import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BusinessManagementService } from './business-management.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ViewEwayDashboardService {
  defaultCriteria1: any;
  defaultCriteria2: any;
  defaultCriteria3: any;

  constructor(private http: HttpClient, private BMS: BusinessManagementService) { }
  defaultCriteria = {};
  activeGSTIN: any;
  getViewEwayDashboardData(active: any): Observable<any> {
    console.trace();
    this.BMS.getActiveBusiness().pipe(
      take(1),
    ).subscribe((activ) => this.activeGSTIN = activ);

    this.defaultCriteria = this.activeGSTIN.defaultquery;
    const response1 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`,
      { type: 'ASSIGNED_TO_ME', defaultquery: this.defaultCriteria, page: 0, size: 10 });
    const response2 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`,
      { type: 'GENERATED_BY_ME', defaultquery: this.defaultCriteria, page: 0, size: 10 });
    const response3 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`,
      { type: 'GENERATED_BY_OTHERS', defaultquery: this.defaultCriteria, page: 0, size: 10 });
    const response4 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`,
      { type: 'MANUAL_ARCHIVE', defaultquery: this.defaultCriteria, page: 0, size: 10 });


    return forkJoin([response1, response2, response3, response4]);
  }

  getViewEwayExpiredData(active: any): Observable<any> {
    console.trace();
    this.BMS.getActiveBusiness().pipe(
      take(1),
    ).subscribe((activ) => this.activeGSTIN = activ);

    this.defaultCriteria = this.activeGSTIN.defaultquery;

    this.defaultCriteria1 = {
      "type": "EWB_EXPIRING_TODAY",
      "defaultquery": this.defaultCriteria,
      "page": "0",
      "size": 10,
      "sortfield": "ewbDate",
      "sortdir": "desc",
      "addlquery": {
        "operator": "and",
        "criterias": [
          {
            "p": "godownId",
            "o": "eq",
            "v": "-1"
          }
        ]
      }
    };
    this.defaultCriteria2 = {
      "type": "EWB_EXPIRING_TODAY",
      "defaultquery": this.defaultCriteria,
      "page": "0",
      "size": 10,
      "sortfield": "ewbDate",
      "sortdir": "desc",
      "addlquery": {
        "operator": "and",
        "criterias": [
          {
            "p": "godownId",
            "o": "neq",
            "v": "-1"
          }
        ]
      }
    };
    this.defaultCriteria3 = {
      "type": "MANUAL_ARCHIVE",
      "defaultquery": this.defaultCriteria,
      "page": "0",
      "size": 10,
      "sortfield": "ewbDate",
      "sortdir": "desc",
      "addlquery": {
        "operator": "and",
        "criterias": [
          {
            "p": "validUpto",
            "o": "eq",
            "v": "TODAY"
          }
        ]
      }
    };
    const response1 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, this.defaultCriteria1);
    const response2 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, this.defaultCriteria2);
    const response3 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, this.defaultCriteria3);
    return forkJoin([response1, response2, response3]);
  }

  getViewEwayExpiredYesterdayData(active: any): Observable<any> {
    console.trace();
    this.BMS.getActiveBusiness().pipe(
      take(1),
    ).subscribe((activ) => this.activeGSTIN = activ);

    this.defaultCriteria = this.activeGSTIN.defaultquery;

    this.defaultCriteria1 = {
      "type": "EWB_EXPIRED_YESTERDAY",
      "defaultquery": this.defaultCriteria,
      "page": "0",
      "size": 10,
      "sortfield": "ewbDate",
      "sortdir": "desc",
      "addlquery": {
        "operator": "and",
        "criterias": [
          {
            "p": "godownId",
            "o": "eq",
            "v": "-1"
          }
        ]
      }
    };
    this.defaultCriteria2 = {
      "type": "EWB_EXPIRED_YESTERDAY",
      "defaultquery": this.defaultCriteria,
      "page": "0",
      "size": 10,
      "sortfield": "ewbDate",
      "sortdir": "desc",
      "addlquery": {
        "operator": "and",
        "criterias": [
          {
            "p": "godownId",
            "o": "neq",
            "v": "-1"
          }
        ]
      }
    };
    const response1 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, this.defaultCriteria1);
    const response2 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, this.defaultCriteria2);
    return forkJoin([response1, response2]);
  }

  getEwayCount(type: string, page: number, size: number, active: any, addlquery?: any): Observable<any> {

    const defaultCriteria = active.defaultquery;
    if (type === 'EWB_EXPIRING_TODAY_GODOWN') {
      type = 'EWB_EXPIRING_TODAY';
      addlquery = JSON.parse(JSON.stringify(addlquery));
      addlquery.criterias.push({ p: 'godownId', o: 'neq', v: '-1' });

    }
    if (type === 'EWB_EXPIRING_TODAY_MOVEMENT') {
      type = 'EWB_EXPIRING_TODAY';
      addlquery = JSON.parse(JSON.stringify(addlquery));
      addlquery.criterias.push({ p: 'godownId', o: 'eq', v: '-1' });
    }
    if (type === 'EWB_EXPIRING_TODAY_GODOWN_YES') {
      type = 'EWB_EXPIRED_YESTERDAY';
      addlquery = JSON.parse(JSON.stringify(addlquery));
      addlquery.criterias.push({ p: 'godownId', o: 'neq', v: '-1' });

    }
    if (type === 'EWB_EXPIRING_TODAY_MOVEMENT_YES') {
      type = 'EWB_EXPIRED_YESTERDAY';
      addlquery = JSON.parse(JSON.stringify(addlquery));
      addlquery.criterias.push({ p: 'godownId', o: 'eq', v: '-1' });
    }
    const response1 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`,
      { type, defaultquery: defaultCriteria, page, size, addlquery });
    return response1;
  }
}
