import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  constructor(
    private http: HttpClient
  ) { }

  private actionType = new BehaviorSubject<string | null>(null);
  private actionDone = new BehaviorSubject(false);
  private godownId = new BehaviorSubject(null);
  private selectedEWBs = new BehaviorSubject(null);
  currentType = this.actionType.asObservable();
  actionStatus = this.actionDone.asObservable();
  currentGodown = this.godownId.asObservable();
  selectedEWBIds = this.selectedEWBs.asObservable();




  setActionType(type: string | null) {
    this.actionType.next(type);
  }
   setActionDone(status :any) {
    this.actionDone.next(status);
  }

  setCurrentGodown(id:any) {
    this.godownId.next(id);
  }

  setSelectedIds(ewbIds:any) {
    this.selectedEWBs.next(ewbIds);
  }

  cancelEWB(data:any, gstin:any) {
    return this.http.put(`${environment.apiUrl}/ewb/cancel?gstin=${gstin}`, data).pipe(map((response: any) => {
      return response;
    }));
  }

  updateEWB(data:any, gstin:any) {
    return this.http.put(`${environment.apiUrl}/ewb/updatePartB?gstin=${gstin}`, data).pipe(map((response: any) => {
      return response;
    }));
  }

  extendEWB(data:any, gstin:any) {
    return this.http.put(`${environment.apiUrl}/ewb/extendValidity?gstin=${gstin}`, data).pipe(map((response: any) => {
      return response;
    }));
  }

  calculateDistance(fromPincode:any,toPincode:any){
    return this.http.get(`${environment.apiUrl}/search/distance?pincode1=${fromPincode}&pincode2=${toPincode}`).pipe(map((response: any) => {
      return response;
    }));
  }

  extendEWBBulk(data:any, gstin:any) {
    return this.http.put(`${environment.apiUrl}/ewb/extendValidity?gstin=${gstin}`, data).pipe(map((response: any) => {
      return { response, ewbNo: data.ewbNo };
    }));
  }

  rejectEWB(data:any, gstin:any) {
    return this.http.put(`${environment.apiUrl}/ewb/reject?gstin=${gstin}`, data).pipe(map((response: any) => {
      return response;
    }));
  }

  assignGodownEWB(data:any, gstn:any) {
    return this.http.post(`${environment.apiUrl}/godown/addEwb?gstin=${gstn}`, data).pipe(map((response: any) => {
      return response;
    }));
  }

  archiveEWBs(data:any, gstn:any) {
    return this.http.put(`${environment.apiUrl}/ewb/archive?gstin=${gstn}&ewbIds=${data['ewbIds']}`,
      data).pipe(map((response: any) => {
        return response;
      }));
  }
  archiveCEWBs(data:any, gstn:any) {
    return this.http.put(`${environment.apiUrl}/ewb/archiveByCEwbNo?gstin=${gstn}`,
      data).pipe(map((response: any) => {
        return response;
      }));
  }

  unarchiveEWBs(data:any, gstn:any) {
    return this.http.delete(`${environment.apiUrl}/ewb/archive?gstin=${gstn}&ewbIds=${data['ewbIds']}`).
      pipe(map((response: any) => {
        return response;
      }));
  }

  removeEWB(data:any, gstn:any) {
    return this.http.post(`${environment.apiUrl}/godown/removeEwb?gstin=${gstn}`, data).pipe(map((response: any) => {
      return response;
    }));
  }

  forceRefreshEWB(ewbId:any, gstn:any) {
    return this.http.get(`${environment.apiUrl}/ewb/refreshEwb?gstin=${gstn}&ewbNo=${ewbId}`).pipe(map((response: any) => {
      return response;
    }));
  }

  regenerateCEWB(data:any, gstn:any) {
    return this.http.post(`${environment.apiUrl}/cewb/regenerate?gstin=${gstn}`, data).pipe(map((response: any) => {
      return response;
    }));
  }

  forceRefreshCEWB(data:any, gstn:any) {
    return this.http.get(`${environment.apiUrl}/cewb/refreshCEwb?cewbNo=${data}&gstin=${gstn}`).
      pipe(map((response: any) => {
        return response;
      }));
  }
  forceRefreshCEWBext(data:any, gstn:any) {
    return this.http.get(`${environment.apiUrl}/cewb/ext?cewbNo=${data}&gstin=${gstn}`).
      pipe(map((response: any) => {
        return response;
      }));
  }
  updatePartBForMuliVehicle(data:any, gstin:any) {
    return this.http.put(`${environment.apiUrl}/ewb/multiVehicle/update?gstin=${gstin}`, data).
      pipe(map((response: any) => {
        return response;
      }));
  }
}
