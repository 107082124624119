import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';


@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  constructor(private snackBar: MatSnackBar) { }

  success(message: string, duration: number = 3000): MatSnackBarRef<SimpleSnackBar> {
    return this.openSnackBar(message, duration, 'success-snackbar');
  }

  warning(message: string, duration: number = 3000): MatSnackBarRef<SimpleSnackBar> {
    return this.openSnackBar(message, duration, 'warning-snackbar');
  }

  error(message: string, duration: number = 3000): MatSnackBarRef<SimpleSnackBar> {
    return this.openSnackBar(message, duration, 'error-snackbar');
  }

  private openSnackBar(
    message: string,
    duration: number,
    panelClass: string
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, "Close", {
      duration,
      panelClass: ['my-snackbar', panelClass],
    });
  }
}
