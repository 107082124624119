<div class="py-3">
    <ul class="list-group">
        <li class="list-group-item cursor-pointer d-flex text-center py-2 bg-transparent border-0" (click)="changeOrganisation()">
            <mat-icon class="d-flex justify-content-start">home</mat-icon>
            <span class="bordera">Select Organisation</span>
        </li>
        <li class="list-group-item cursor-pointer d-flex text-center py-2 bg-transparent border-0" (click)="addOrganisations()">
            <mat-icon class="d-flex justify-content-start">add</mat-icon>
            <span class="bordera">Add Organisation</span>
        </li>
        <li class="list-group-item cursor-pointer d-flex text-center py-2 bg-transparent border-0" *ngIf="sysRole == 1" (click)="navigateToGSTINs()">
            <mat-icon class="d-flex justify-content-start">build</mat-icon>
            <span class="bordera">GSTINs</span>
        </li>
        <li class="list-group-item cursor-pointer d-flex text-center py-2 bg-transparent border-0" *ngIf="sysRole == 1" (click)="navigateToGodown()">
            <mat-icon class="d-flex justify-content-start">business</mat-icon>
            <span class="bordera">Godowns</span>
        </li>
        <li class="list-group-item cursor-pointer d-flex text-center py-2 bg-transparent border-0"
            *ngIf="(currentRole == 0 || currentRole == 1) && !isOrganisation" (click)="stats()">
            <mat-icon class="d-flex justify-content-start"> bar_chart</mat-icon>
            <span class="bordera">Scheduler Stats</span>
        </li>
        <li class="list-group-item cursor-pointer d-flex text-center py-2 bg-transparent border-0"
            *ngIf="((currentRole == 0 || currentRole == 1) && currentRole != 2) || isOrganisation" (click)="reports()">
            <mat-icon class="d-flex justify-content-start">download</mat-icon>
            <span class="bordera">Reports</span>
        </li>
        <li class="list-group-item cursor-pointer d-flex text-center py-2 bg-transparent border-0" (click)="userAccount()">
            <mat-icon class="d-flex justify-content-start">person</mat-icon>
            <span class="bordera">My Profile</span>
        </li>
        <li class="list-group-item cursor-pointer d-flex text-center py-2 bg-transparent border-0" (click)="twoFactorAuth()">
            <mat-icon class="d-flex justify-content-start">lock</mat-icon>
            <span class="bordera">2 Factor Authentication <p class="m-0 p-0 text-red fw-14 px-3">New</p></span>
        </li>
        <li class="list-group-item cursor-pointer d-flex text-center py-2 bg-transparent border-0" (click)="help()">
            <mat-icon class="d-flex justify-content-start">help</mat-icon>
            <span class="bordera">Help-User Manual</span>
        </li>
        <li class=" list-group-item cursor-pointer d-flex text-center py-2 bg-transparent border-0" (click)="logOut()">
            <mat-icon class="d-flex justify-content-start">subdirectory_arrow_right</mat-icon>
            <span class="bordera">Logout</span>
        </li>
    </ul>
</div>