<div class="container">
    <app-header></app-header>
    <app-gstin-header></app-gstin-header>
    <div class="row">
        <div class=" pageTitle">
            <span>  My WatchList</span>
          </div>
           
        <div class="row" *ngIf="flag">
            <form [formGroup]="watchlistName">
                <mat-card class="p-0 mb-2 border-bottom">
                    <mat-card-header class="pb-0">
                        <mat-card-subtitle>WatchList</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="m-0 p-0 mx-5">
                        <mat-form-field class="w-100 mb10">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="Enter the WatchList Name" formControlName="name"
                                class="landingInput">
                            <mat-error *ngIf="watchlistName.controls['name']?.hasError('required')">Name is
                                required</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </form>
            <form [formGroup]="criteriaForm">
                <mat-card class="p-3 mb-3">
                    <mat-card-header class="pb-0">
                        <mat-card-subtitle>Criteria</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="pt-0">
                        <div [hidden]="editForm && selectFlag">
                            <mat-form-field class="w-100">
                                <mat-label>Field</mat-label>
                                <mat-select placeholder="Select Field" (selectionChange)="selectionChanged($event)"
                                    formControlName="p" class="landingInput">
                                    <mat-option *ngFor="let data of consignerList"
                                        [value]="data.value">{{data.key}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="w-100">
                                <mat-label>Condition</mat-label>
                                <mat-select placeholder="Select  Condition"
                                    (selectionChange)="operatorSelection($event)" formControlName="o"
                                    class="landingInput">
                                    <mat-option
                                        *ngIf="optionFlag || label === 'Distance of Transportation' || label === 'Date'"
                                        value="eq">
                                        Equals to
                                    </mat-option>
                                    <mat-option *ngIf="inOperator" value="in">Is One of</mat-option>
                                    <mat-option *ngIf="!optionFlag" value="lt">Less than</mat-option>
                                    <mat-option *ngIf="!optionFlag" value="gt">Greater than</mat-option>
                                    <mat-option *ngIf="!optionFlag" value="lte">Less than or Equals to</mat-option>
                                    <mat-option *ngIf="!optionFlag" value="gte">Greater than or Equals
                                        to</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="w-100">
                                <mat-label [ngClass]="label != 'Date' ? 'formLable' : 'formLable inputDateLbl'">{{label
                                    ?
                                    label
                                    : 'Value'}}</mat-label>
                                <ng-container [ngSwitch]="label">
                                    <div *ngIf="selectedValue == 'validUpto'" class="d-flex justify-content-between">
                                            <input matInput [matDatepicker]="myDatepicker" formControlName="v">
                                            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                                            <mat-datepicker #myDatepicker></mat-datepicker>
                                    </div>
                                    <mat-select *ngSwitchCase="'Status'" placeholder="Select Status" multiple
                                        formControlName="v" class="form-controll landingInput">
                                        <mat-option value="ACTIVE">Active</mat-option>
                                        <mat-option value="ACTIVE_INVALID">Only Part A</mat-option>
                                    </mat-select>
                                    <mat-select *ngSwitchCase="'State Code'" placeholder="Select Status"
                                        formControlName="v" [multiple]="multipleflag"
                                        class="form-controll landingInput">
                                        <mat-option *ngFor="let data of stateList"
                                            [value]="data.key">{{data.value}}</mat-option>
                                    </mat-select>
                                    <input *ngSwitchDefault matInput placeholder="Enter the value" formControlName="v">
                                </ng-container>
                                <mat-error *ngIf="criteriaForm.get('v')?.hasError('required')">Value is
                                    required</mat-error>
                                <mat-error
                                    *ngIf="label === 'Vehicle No' && criteriaForm.get('v')?.hasError('pattern')">Invalid
                                    Vehicle No</mat-error>
                            </mat-form-field>
                        </div>

                        <div *ngIf="criteriaList && criteriaList.length > 0">
                            <div *ngFor="let data of criteriaList;" class="mb10 mt10 borderDashed d-flex justify-content-between">
                                <div class="p-3">
                                <p *ngIf="!editForm" class="m-0">{{data.p}} {{data.o}} {{data.v}}</p>
                                <p *ngIf="editForm" class="m-0" (click)="editCriteria(data)">{{data.p}} {{data.o}}
                                    {{data.v}}
                                </p>
                            </div>
                                <button mat-icon-button (click)="remove_criteria(data)" class="btn btn-sm btn-danger">
                                   <mat-icon>delete </mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="d-flex  mt-3 mb10">
                            <button *ngIf="!editForm" color="primary" (click)="addWatchlist()"
                                mat-raised-button>Save</button>
                            <button *ngIf="editForm" color="primary" (click)="edit_Criteria()"
                                mat-raised-button>Save</button>
                            <button color="primary" class="mx-3" (click)="addData()" mat-raised-button>Add Criteria
                            </button>
                        </div>

                    </mat-card-content>
                </mat-card>
            </form>

        </div>
        <app-footer-bar></app-footer-bar>

    </div>
</div>