import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BusinessManagementService } from './business-management.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  lastFetched: any;
  stats: { [key: string]: any[] } = {};

  defaultCriteria = {};
  activeGSTIN: any;
  constructor(private http: HttpClient, private BMS: BusinessManagementService) { }

  // getData(active: any, from = null): Observable<any> {
  //   this.BMS.getActiveBusiness().pipe(take(1)).subscribe((activ) => this.activeGSTIN = activ);
  //   if (this.stats[active.gstn]?.length && this.lastFetched && (new Date().getTime() - this.lastFetched) < 15 * 60 * 1000) {

  //     return of(this.stats[active.gstn]);
  //   }

  //   this.lastFetched = new Date().getTime();
  //   this.defaultCriteria = this.activeGSTIN.defaultquery;

  //   this.stats[active.gstn] = [];

  //   const types = ['EWB_EXPIRING_TODAY', 'PARTB_NOT_UPDATED_FOR_12D', 'MY_EWB', 'EWB_WITHIN_200KM', 'ONLY_PART_A_EWB', '', 'NATURE:1', 'NATURE:2', 'NATURE:4', 'NATURE:3', 'EWB_EXPIRED_YESTERDAY'];

  //   const apiFns = types.map((type, index) => {
  //     let path = environment.apiUrl;
  //     if (type.includes('NATURE')) {
  //       const nature = type.split(':')[1];
  //       path = `${path}/scheduler/stats?gstin=${active.gstn}&nature=${nature}`;
  //       return this.http.get(path).pipe(map((res) => {
  //         this.stats[active.gstn][index] = res;
  //         return res;
  //       }));
  //     }

  //     let payload: any = {};
  //     if (type === '') {
  //       path = `${path}/cewb/count/org?gstin=${active.gstn}`;
  //       payload = { type: "", page: "0", size: "0", defaultquery: { operator: "and", criterias: [{ p: "userGstin", o: "eq", v: active.gstn }] } };
  //     } else {
  //       path = `${path}/ewb/count?gstin=${active.gstn}`;
  //       payload = { type, defaultquery: this.defaultCriteria };
  //     }

  //     return this.http.post(path, payload).pipe(map((res) => {
  //       this.stats[active.gstn][index] = res;
  //       return res;
  //     }));
  //   });

  //   return forkJoin(apiFns);

  // }
 
  getCountData(active: any, from = null): Observable<any> {
    this.BMS.getActiveBusiness().pipe(take(1)).subscribe((activ) => this.activeGSTIN = activ); 
    //localStorage.setItem('lastFetchedCount', new Date().getTime().toString());
    // this.lastFetched = Number(localStorage.getItem('lastFetchedCount'));
    // const dashboardData = localStorage.getItem('dashboardData');
    // const fifteenMinutesInMs = 2 * 1000;
    // if (dashboardData && (new Date().getTime() - this.lastFetched) < fifteenMinutesInMs) {
    //   return of(JSON.parse(dashboardData));
    // }
     this.stats[active.gstn] = [];
    return this.http.get(`${environment.apiUrl}/ewb/dashboardCount?gstin=${active.gstn}`)
      .pipe(map((res) => {
        localStorage.setItem('dashboardData', JSON.stringify(res));
        return res;
      }));
  }
  getStatsData(active: any, from = null): Observable<any> {
    this.BMS.getActiveBusiness().pipe(take(1)).subscribe((activ) => this.activeGSTIN = activ);  
    // localStorage.setItem('lastFetchedCount', new Date().getTime().toString());
    // this.lastFetched = Number(localStorage.getItem('lastFetchedCount'));
    // const dashboardStatsData = localStorage.getItem('dashboardStatsData');
    // const fifteenMinutesInMs = 2 * 1000;
    // if (dashboardStatsData && (new Date().getTime() - this.lastFetched) < fifteenMinutesInMs) {
    //   return of(JSON.parse(dashboardStatsData));
    // }   
    this.stats[active.gstn] = [];
    return this.http.get(`${environment.apiUrl}/scheduler/schedulerStats?gstin=${active.gstn}`)
    .pipe(map((res) => {
      localStorage.setItem('dashboardStatsData', JSON.stringify(res));
      return res;
    }));
  }
}
