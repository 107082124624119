import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { FakeBackendInterceptor } from '../../_helpers/fake-backend';
import { environment } from '../../../environments/environment';
import { TokenService } from './token.service';
import { BaseResponse } from '../../_models/Response';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  initialLoginValue = this.isAuthenticated()
    ? 2
    : this.isLoginAuthenticated()
      ? 1
      : 0;
  loginIndicator = new BehaviorSubject(this.initialLoginValue); // 0: Not logged in , 1: Init Login Done, 2: Fully Logged In
  subscriptionSource = new BehaviorSubject(null);
  subscription = this.subscriptionSource.asObservable();
  //toasterService: any;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private toaster: ToasterService,
    private router: Router,
    private fbInterceptor: FakeBackendInterceptor
  ) { }

  getLoginIndicator(): Observable<any> {
    return this.loginIndicator.asObservable();
  }

  setLoginIndicator(data: number): boolean {
    this.loginIndicator.next(data);
    return true;
  }

  setSubscription(subscription: any) {
    if (subscription === undefined) {
      subscription = {
        enddate: '2019-12-15',
        actualcount: 0,
        count: 25100,
      };
    }
    this.subscriptionSource.next(subscription);
    localStorage.setItem('info', String(JSON.stringify(subscription)));
  }
  isAuthenticated(): boolean {
    const token = localStorage.getItem('jwt') || undefined; // Assign `undefined` if token is `null`
    return !this.tokenService.isTokenExpired(token);
  }

  isLoginAuthenticated(): boolean {
    const token = localStorage.getItem('logintoken') || undefined;
    return !this.tokenService.isTokenExpired(token);
  }

  login(data: any) {
    return this.http.post(`${environment.apiUrl}/auth/initlogin`, data).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }


  loginComplete(data: any) {
    return this.http.post(`${environment.apiUrl}/auth/completelogin`, data).pipe(
      map((response: any) => {
        return response as BaseResponse;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }


  register(data: any) {
    return this.http.post(`${environment.apiUrl}/auth/sendotp`, data).pipe(
      map((response: any) => {
        return response as BaseResponse;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }
  settingPassword(data: any) {
    return this.http.post(`${environment.apiUrl}/auth/verifytoken`, data).pipe(
      map((response: any) => {
        return response as BaseResponse;
      }),
      catchError((error: any) => {
        throw error; // Throw the error to propagate it further
      })
    );
  }

  resetPassword(data: any) {
    return this.http
      .post(`${environment.apiUrl}/auth/resetpassword`, data)
      .pipe(
        map((response: any) => {
          return response as BaseResponse;
        }),
        catchError((error: any) => {

          throw error;
        })
      );
  }

  forgotPassword(userid: any) {
    return this.http
      .get(`${environment.apiUrl}/auth/forgotpassword?userid=${userid}`)
      .pipe(
        map((response: any) => {
          return response as BaseResponse;
        }),
        catchError((error: any) => {

          throw error;
        })
      );
  }
  verifyOtp(data: any) {
    return this.http
      .get(
        `${environment.apiUrl}/auth/verifyotp?userid=${data.userid}&otp=${data.otp}`
      )
      .pipe(
        map((response: any) => {
          return response as BaseResponse;
        }),
        catchError((error: any) => {

          throw error;
        })
      );
  }

  
  loginVerifyTotp(code: any, userid: any) {
    return this.http.get(`${environment.apiUrl}/auth/verifytotp?code=${code.otpValue}&userId=${userid}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  
  fetchAllPlan(type = 10) {
    return this.http.get(`${environment.apiUrl}/auth/plans?type=${type}`).pipe(
      map((response: any) => {
        return response as BaseResponse;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  applyPromocode(data: any) {
    return this.http
      .post(
        `https://2e93e177-a3cd-440d-986d-3eab554a71c0.mock.pstmn.io/auth/promo`,
        data
      )
      .pipe(
        map((response: any) => {
          return response as BaseResponse;
        }),
        catchError((error: any) => {

          throw error;
        })
      );
  }

  initLoginAfterAd(token: string) {
    this.httpOptions.headers = new HttpHeaders({ 'token': token });
    return this.http
      .post(
        `${environment.apiUrl}/auth//initadlogin`,
        {},
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return response as BaseResponse;
        }),
        catchError((error: any) => {

          throw error;
        })
      );
  }

  createOrder(data: any, type = 10, tpl = 0) {
    let urlsTring = `${environment.apiUrl}/auth/checkout?userid=${data.userid}&orgName=${data.orgName}
    &plancode=${data.plancode}&type=${type}&tpl=${tpl}`;

    if (data.couponcode !== '') {
      urlsTring += `&couponcode=${data.couponcode}`;
    }

    return this.http.get(urlsTring).pipe(
      map((response: any) => {
        return response as BaseResponse;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  renewPlan(plan: any, type = 11, tpl = 0, couponCode = '') {
    let urlsTring = `${environment.apiUrl}/org/subscription/renew?plancode=${plan.code}&type=${type}&tpl=${tpl}`;

    if (couponCode !== '') {
      urlsTring += `&couponcode=${couponCode}`;
    }
    return this.http.get(urlsTring).pipe(
      map((response: any) => {
        return response as BaseResponse;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  fetchOrderDetail(orderId: any, userId: any) {
    return this.http
      .get(
        `${environment.apiUrl}/auth/payment?orderid=${orderId}&userid=${userId}`
      )
      .pipe(
        map((response: any) => {
          return response as BaseResponse;
        }),
        catchError((error: any) => {

          throw error;
        })
      );
  }
  getDomainName() {
    return this.http.get(`${environment.apiUrl}/auth/domain/list`).pipe(
      map((response: any) => {
        return response as BaseResponse;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }
  getSAMLLoginLink(domainName: string): Observable<BaseResponse> {
    this.httpOptions.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .get(`${environment.apiUrl}/auth/sso?domain=${domainName}`, this.httpOptions)
      .pipe(
        map((response) => {
          return <BaseResponse>response;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }
  // validateToken(token: string): Observable<BaseResponse> {
  //   return this.http
  //     .get(`${environment.apiUrl}/validateToken?token=${token}`)
  //     .pipe(
  //       map((response) => {
  //         return <BaseResponse>response;
  //       }),
  //       retry(1),
  //       catchError(this.handleError)
  //     );
  // }
  handleError = (error: any) => {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toaster.warning( "There was an error while retrieving data. Please refresh the page.");
    // this.tokenService.showError(
    //   "There was an error while retrieving data. Please refresh the page."
    // );
    return throwError(() => {
      return errorMessage;
    });
  };
}

