import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './shared/services/auth.service';
import { ApiInterceptor } from './shared/interceptor/api.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LoaderService } from './shared/services/loader.service';
import { ViewEwayDashboardResolverService } from './shared/services/resolver/view-eway-dashboard-resolver.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ResponseInterceptor } from './shared/interceptor/response.interceptor';
import { DatePipe } from '@angular/common';
import { CacheInterceptor } from './shared/interceptor/cache.interceptor';
import { TermsModalComponent } from './shared/modal/terms-modal/terms-modal.component';
import { VideoNameComponent } from './shared/modal/video-name/video-name.component';
import { EditGstinVideoComponent } from './shared/modal/edit-gstin-video/edit-gstin-video.component';
import { ViewEwayExpiredResolverService } from './shared/services/resolver/view-eway-expired-resolver.service';
import { ViewMapComponent } from './shared/modal/view-map/view-map.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToasterService } from './shared/services/toaster.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ShortNumberPipePipe } from './pipes/short-number.pipe.pipe';
import { TokenInterceptor } from './shared/interceptor/token.interceptor';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingInterceptor } from './shared/interceptor/loading.interceptor';
import { WidgetsModule } from './widgets/widgets.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { AutoAlertComponent } from './shared/components/auto-alert/auto-alert.component';
import { ExtentValidityCewbModalComponent } from './shared/components/extent-validity-cewb-modal/extent-validity-cewb-modal.component';
import { GenerateEwbErrorModalComponent } from './generate-ewb-error-modal/generate-ewb-error-modal.component';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    TermsModalComponent,
    VideoNameComponent,
    EditGstinVideoComponent,
    ViewMapComponent,
    AutoAlertComponent,
    ExtentValidityCewbModalComponent,
    GenerateEwbErrorModalComponent,

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js',
      { enabled: environment.production }),
    BrowserAnimationsModule,
    WidgetsModule,
    DashboardModule,
    // add the angular material 
    MatSnackBarModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    FormsModule,
    MatRadioModule,
    MatCardModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatProgressBarModule,
    MatInputModule, 
    DataTablesModule,  
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DatePipe,
    ShortNumberPipePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    AuthService,
    LoaderService,
    ViewEwayDashboardResolverService,
    ViewEwayExpiredResolverService,
    ToasterService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
