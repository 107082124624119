import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BusinessManagementService } from './business-management.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  lastFetched: number = 0;
  stats: { [key: string]: any[] } = {};

  defaultCriteria = {};
  activeGSTIN: any;
  constructor(private http: HttpClient, private BMS: BusinessManagementService) { }

  getData(active: any, from = null): Observable<any> {
    this.BMS.getActiveBusiness().pipe(take(1)).subscribe((activ) => this.activeGSTIN = activ);
    if (this.stats[active.gstn]?.length && this.lastFetched && (new Date().getTime() - this.lastFetched) < 20*1000) {
      
      return of(this.stats[active.gstn]);
    }

    this.lastFetched = new Date().getTime();
    this.defaultCriteria = this.activeGSTIN.defaultquery;

    this.stats[active.gstn] = [];

    const types = ['EWB_EXPIRING_TODAY', 'PARTB_NOT_UPDATED_FOR_12D', 'MY_EWB', 'EWB_WITHIN_200KM', 'ONLY_PART_A_EWB', '', 'NATURE:1', 'NATURE:2', 'NATURE:4', 'NATURE:3', 'EWB_EXPIRED_YESTERDAY'];

    const apiFns = types.map((type, index) => {
      let path = environment.apiUrl;
      if (type.includes('NATURE')) {
        const nature = type.split(':')[1];
        path = `${path}/scheduler/stats?gstin=${active.gstn}&nature=${nature}`;
        return this.http.get(path).pipe(map((res) => { 
          this.stats[active.gstn][index] = res;
          return res;
        }));
      }

      let payload: any = {};
      if (type === '') {
        path = `${path}/cewb/count/org?gstin=${active.gstn}`;
        payload = { type: "", page: "0", size: "0", defaultquery: { operator: "and", criterias: [{ p: "userGstin", o: "eq", v: active.gstn }] } };
      } else {
        path = `${path}/ewb/count?gstin=${active.gstn}`;
        payload = { type, defaultquery: this.defaultCriteria };
      }

      return this.http.post(path, payload).pipe(map((res) => { 
        this.stats[active.gstn][index] = res;
        return res;
      }));
    });

    return forkJoin(apiFns);

    // let payload: any = { type: 'MY_EWB', defaultquery: this.defaultCriteria };
    // const response1 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, payload).pipe(map((res) => {
    //   this.stats[active.gstn][2] = res;
    // }));

    // payload.type = 'EWB_WITHIN_200KM';
    // const response2 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, payload).pipe(map((res) => {
    //   this.stats[active.gstn][3] = res;
    // }));

    // payload.type = 'EWB_EXPIRING_TODAY';
    // const response3 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, payload).pipe(map((res) => {
    //   this.stats[active.gstn][0] = res;
    // }));

    // payload.type = 'PARTB_NOT_UPDATED_FOR_12D';
    // const response4 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, payload).pipe(map((res) => {
    //   this.stats[active.gstn][1] = res;
    // }));

    // payload.type = 'ONLY_PART_A_EWB';
    // const response5 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, payload).pipe(map((res) => {
    //   this.stats[active.gstn][4] = res;
    // }));

    // payload.type = 'EWB_EXPIRED_YESTERDAY';
    // const response7 = this.http.post(`${environment.apiUrl}/ewb/count?gstin=${active.gstn}`, payload).pipe(map((res) => {
    //   this.stats[active.gstn][10] = res;
    // }));

    // payload = { type: "", page: "0", size: "0", defaultquery: { operator: "and", criterias: [{ p: "userGstin", o: "eq", v: active.gstn }] } };
    // const response6 = this.http.post(`${environment.apiUrl}/cewb/count/org?gstin=${active.gstn}`, payload).pipe(map((res) => {
    //   this.stats[active.gstn][5] = res;
    // }));


    // const stat1 = this.http.get(`${environment.apiUrl}/scheduler/stats?gstin=${active.gstn}&nature=1`).pipe(map((res) => {
    //   this.stats[active.gstn][6] = res;
    // }));
    // const stat2 = this.http.get(`${environment.apiUrl}/scheduler/stats?gstin=${active.gstn}&nature=2`).pipe(map((res) => {
    //   this.stats[active.gstn][7] = res;
    // }));
    // const stat4 = this.http.get(`${environment.apiUrl}/scheduler/stats?gstin=${active.gstn}&nature=4`).pipe(map((res) => {
    //   this.stats[active.gstn][8] = res;
    // }));
    // const stat3 = this.http.get(`${environment.apiUrl}/scheduler/stats?gstin=${active.gstn}&nature=3`).pipe(map((res) => {
    //   this.stats[active.gstn][9] = res;
    // }));

    // return forkJoin([response3, response4, response1, response2, response5, response6, stat1, stat2, stat4, stat3, response7]);
  }
}
