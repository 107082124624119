import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenService } from '../services/token.service';
import { ToasterService } from '../services/toaster.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private TS: TokenService,
        private toaster: ToasterService,
        private auth: AuthService
    ) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
       
        if (err.status === 401 || err.status === 403 || err.status === 400) {
            if (err !== null) {
            if (err?.url?.trim() === 'https://dev.api.easywaybill.in/ezewb/v1/auth/resetpassword' || 
                 err?.url?.trim() === 'https://dev.api.easywaybill.in/ezewb/v1/mfa/register?gstin=null' || 
                 err?.url?.trim()==='https://dev.api.easywaybill.in/ezewb/v1/scheduler/progress?gstin=undefined'||
                 err?.url?.trim()==='https://dev.api.easywaybill.in/ezewb/v1/scheduler/stats?gstin=05AAAAT2562R1Z3&nature=1')
            {
                return throwError(err);
            }
        }
            this.auth.setLoginIndicator(0);
            this.TS.destroy();
            this.router.navigateByUrl(`/auth/login`);
            this.toaster.warning('Session Expired. Login again!')
            return of(err.message);
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url } = req;
        if (url.indexOf('/count') > -1 || url.indexOf('/search') > -1) { return next.handle(req); }
        return next.handle(req).pipe
        (
            catchError(x => this.handleAuthError(x))
        );
    }
}

