import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { WidgetsService } from '../widgets.service';
import { BusinessManagementService } from '../../shared/services/business-management.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { take } from 'rxjs/operators';
import { LoaderService } from '../../shared/services/loader.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from 'src/app/shared/components/alert/alert.component';
@Component({
  selector: 'app-watch-list',
  templateUrl: './watch-list.component.html',
  styleUrls: ['./watch-list.component.scss'],
})
export class WatchListComponent implements OnInit {
  datas: any;
  business$: any;
  activeData: any;
  page = 0;
  loginInd: any;
  gstins: any[];
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  loading = new BehaviorSubject<boolean>(true);
  scrolldisplay: boolean = false;
  constructor(
    private widgetsService: WidgetsService,
    private activeBusiness: BusinessManagementService,
    private toaster: ToasterService,
    private router: Router,
    private auth: AuthService,
    public dialog: MatDialog
  ) {
    this.gstins = [];
  }

  ngOnInit() {
    this.activeBusiness.fetchGstinList().subscribe((response) => {
      this.gstins = response;
    });
    this.business$ = this.activeBusiness
    .getActiveBusiness()
    .subscribe((active) => {
      this.auth
      .getLoginIndicator()
      .pipe(take(1))
      .subscribe((ind) => (this.loginInd = ind));
      if (active === null && this.loginInd === 2) {
        this.toaster.warning('No GSTIN selected');
          this.scrolldisplay=false
      } else if (active != null && this.loginInd === 2) {
        this.activeData = active;
        this.widgetsService.fetchWatchList(active.gstn).subscribe(
          (response: any) => {
            if (response.response.length==0){
              this.loading.next(false)
            }
            if (response.status === 1) {
              this.datas = response.response;
          this.scrolldisplay=true
              this.widgetsService
              .countWatchlist(this.activeData, this.datas)
              .subscribe(
                (response: any) => {
                  if (response.length) {
                    response.forEach((item: any, index: any) => {
                      this.datas[index].count = item['response'];
                    });
                  }
                },
                (e) => {}
              );
            }
          },
          (e) => {}
        );
      }
    });
  }

  addWatchList() {
    this.router.navigate(['/watch-list-crud']);
  }

  editWatchList(data: any) {
    this.router.navigate([`watch-list-crud/${data.id}`]);
  }

  async deleteAlert(data: any) {
    
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message:"Are you sure you want to delete the WatchList ?",
        action:"",
        data
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    
    if(result.action === "delete") {
      this.deleteWatchList(result.data)
    }
    });
  }

  deleteWatchList(data: any) {
    this.widgetsService.deleteWatchList(data).subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.datas.forEach((element: any, index: any) => {
            if (element.id == data.id && element.name == data.name) {
              this.datas.splice(index, 1);
            }
          });
          this.toaster.success(`${response.message}`);
        } else {
          this.toaster.warning(`${response.errorList[0].message}`);
        }
      },
      (error) => {
        this.toaster.warning(error);
      }
    );
  }

  ngOnDestroy() {
    this.business$.unsubscribe();
  }

  doInfinite() {
    this.loading.next(true);
    this.widgetsService.getWatchListData(this.activeData, this.page).subscribe(
      (response: any) => {
        if (response.status !== 0) {
          if (response[0].response.length === 0) {
          }
          this.datas = [...this.datas, ...response[0].response];
          this.page++;
        } else {
          this.toaster.warning(response.message);
        }
      },
      (e) => {
        this.toaster.warning(e.message);
      }
    );
    this.loading.next(false);
  }

  viewWatchList(watchListID: any) {
    this.datas.forEach((item: any) => {
      if (item.id === watchListID) {
        if (item.isDefault !== 2) {
          this.router.navigate([
            `/view-eway/${'VIEW_WATCHLIST'}/${watchListID}`,
          ]);
        } else {
          this.router.navigate([
            `/view-eway/${'SYSTEM_GENERATED_WATCHLIST'}/${watchListID}`,
          ]);
        }
      }
    });
  }
}
