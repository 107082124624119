import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BusinessManagementService } from 'src/app/shared/services/business-management.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { TokenService } from 'src/app/shared/services/token.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  constructor(
    private tS: TokenService,
    private BMS: BusinessManagementService,
    private router: Router,
    private auth: AuthService,
    private toaster: ToasterService,
    public dialogRef: MatDialogRef<MenuComponent>,

  ) { }
  homepage: any;
  isOrganisation: any;
  currentRole: any;
  gstinData: any;
  gstinRole: any;
  sysRole: any;
  currentPopover: any;

  async ngOnInit() {

    this.BMS.activeBusiness.subscribe((res) => {
      if (res) {

        this.isOrganisation = res.isOrganisation;
        this.currentRole = res.role;
      }
    })
    if(localStorage.getItem('selectedOrganisation')){
    let org = JSON.parse(localStorage.getItem('selectedOrganisation') || "");
    // this.isOrganisation = org;
    this.sysRole = org ? org.sysadmin : '';
    }

  }
  dashboard() {
    this.router.navigate(['/dashboard']);
    this.dialogRef.close()
  }
  
  changeOrganisation() {
    if (this.BMS.setActiveBusiness(null)) {
      this.auth.setLoginIndicator(1);
      this.router.navigate(['/select-organisation']);
    } else {
      this.toaster.warning('Something Went wrong !');
    }
    this.dialogRef.close()
  }
 
  stats() {
    this.router.navigate(['/dashboard/stats']);
    this.dialogRef.close()
  }
  reports() {
    this.router.navigateByUrl('/reports');
    this.dialogRef.close()
  }
  help() {
    this.router.navigate(['/help']);
    this.dialogRef.close()
  }
  addOrganisations() {
    this.router.navigateByUrl('/add-organisation');
    this.dialogRef.close()
  }

  twoFactorAuth() {
    this.router.navigateByUrl('two-fa');
    this.dialogRef.close()
  }
  userAccount() {
    this.router.navigate(['/user-profile']);
    this.dialogRef.close()
  }
  navigateToGodown() {
    this.router.navigate(['/godown-list']);
    this.dialogRef.close()
  }
  navigateToGSTINs() {
    this.router.navigate(['/gstin-list']);
    this.dialogRef.close()
  }
  logOut() {
    const destroy = this.tS.destroy()
    if (destroy) {
      this.auth.setLoginIndicator(0);
      this.router.navigate(['/auth/login']);
    } else {
      this.toaster.warning('Something Went wrong !');
    }
    this.dialogRef.close()
  }
}
