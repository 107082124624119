import { Injectable } from '@angular/core';
import { BusinessManagementService } from '../business-management.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewEwayExpiredResolverService {
  constructor(

    private activeBusiness: BusinessManagementService,
  ) { }

  activeGstin = false;
  activeBus: any;

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.activeBusiness.getActiveBusiness().subscribe((active) => {
      if (active && !active.isOrganisation) {
        if (active === null) { this.activeGstin = false; } else { this.activeGstin = true; this.activeBus = active; }
      }
    });

    if (this.activeGstin) {
      if (route.routeConfig && route.routeConfig.path === 'view-eway-expiry-yesterday') {
        // return this.backend.getViewEwayExpiredYesterdayData(this.activeBus);
      } else {
        // return this.backend.getViewEwayExpiredData(this.activeBus);
      }
    }
  }
}
