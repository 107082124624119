import {
  Component,
  Input,
  EventEmitter,
  Output,
  HostListener,
} from '@angular/core';
import { TokenService } from '../../services/token.service';
import { BusinessManagementService } from '../../services/business-management.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GstinHeaderDialogComponent } from './gstin-header-dialog/gstin-header-dialog.component';

@Component({
  selector: 'app-gstin-header',
  templateUrl: './gstin-header.component.html',
  styleUrls: ['./gstin-header.component.scss'],
})
export class GstinHeaderComponent {

  @Input() backbutton = false;
  
  selectedGSTIN: any;
  orgs: any;
  selectedBusiness: any;
  @Input() gstins: any[] = [];
  activeBusiness: Subscription | null;
  @Output() refreshData = new EventEmitter<any>();
  selectedOrganisation: any | null;
  parentOrganisationSeleced: any;
  isOrganisation: boolean = false;
  isShowGstinHeader: boolean = false;
  label: boolean = false;
  gstinName!: string;

  constructor(
    private localStorage: TokenService,
    private businessService: BusinessManagementService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.gstins = [];
    this.backbutton = false;
    this.selectedBusiness = null;
    this.selectedGSTIN = null;
    this.activeBusiness = null;
  }
  ngOnInit() {
    if(localStorage.getItem('mfaEnabled') ===  'true'){
    this.businessService.fetchGstinList().subscribe((response:any) => {
      this.gstins = response;
    });
    }
    if (this.gstins && this.gstins.length === 1) {
      this.businessService.setActiveBusiness(this.gstins[0]);
      this.isShowGstinHeader = false;
    }else{
      this.isShowGstinHeader = true;
    }
    if(localStorage.getItem('businesses')){
     this.gstins = JSON.parse(localStorage.getItem('businesses') || '');
    }
   
    if(localStorage.getItem('selectedOrganisation')){
     this.parentOrganisationSeleced = JSON.parse(localStorage.getItem('selectedOrganisation') || '');
    }else{
      const organisations =  localStorage.getItem('organisations')
      this.parentOrganisationSeleced = organisations![0];
    }
    if (this.parentOrganisationSeleced && this.parentOrganisationSeleced.sysadmin == 1) {
      this.selectedOrganisation = JSON.parse(localStorage.getItem('selectedOrganisation') || '');
      this.selectedOrganisation.isOrganisation = true;
      if (this.gstins) {
        let organisationSelected = this.gstins.map((d: any) => d.isOrganisation);
        let isOrganisationSelected = organisationSelected.find((d: any) => d);
        if (!isOrganisationSelected) {
          this.gstins ? this.gstins.unshift(this.selectedOrganisation) : null;
        }
      }
      this.activeBusiness = this.businessService.getActiveBusiness().subscribe((active) => {
          this.selectedGSTIN = active ? active.isOrganisation ? active.organisation.orgname : active.gstn : 'Tap to Select an Organisation or a GSTIN/TransId';
          if (active && active.isOrganisation) {
            active.isOrganisation = true;
            this.isOrganisation = true;
          } else {
            this.isOrganisation = false;
          }
        });
    } else {
      this.activeBusiness = this.businessService.getActiveBusiness().subscribe((active) => {
          this.selectedGSTIN = active ? active.gstn : 'Tap to Select a GSTIN';
        });
    }
  }
  opendilog() {
    let temp;
    if (this.orgs === undefined) {
      temp = localStorage.getItem('selectedGSTIN')
    }
    const dialogRef = this.dialog.open(GstinHeaderDialogComponent, {
      data: {
        gstins: this.gstins,
        selectedOrganisation: this.selectedOrganisation,
        selectedGSTIN: this.selectedGSTIN,
        orgs: this.orgs,
        temp: temp
      },
      width: '600px',
      height: '330px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.orgs = result.isOrganisation;
        if (result.name) {
          this.gstinName = result.name
        }
        this.gstinSelected(result);
      }
    });
  }
  gstinSelected($event: any) {
    let gstinData: any = this.gstins.find(g => g.gstn === $event.gstn);
    if ($event.isOrganisation === true) {
      this.isOrganisation = true;
      this.localStorage.setSelectedGSTIN(null)
      this.businessService.setActiveBusiness({ organisation: this.selectedOrganisation, isOrganisation: true });
    } else {
      this.isOrganisation = false;
      this.selectedBusiness = $event
      this.localStorage.setSelectedGSTIN(JSON.stringify(gstinData));
      this.selectedGSTIN = this.selectedBusiness.orgname;
      this.businessService.setActiveBusiness(this.selectedBusiness);
    }
  }
  toAddGstin() {
    this.router.navigateByUrl('/add-gstin');
  }
  goBack() {
    if (this.router.url === '/dashboard/home') {
      return
    } else {
      window.history.back();
    }
  }
}
