import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './guards/auth.guard.service';
import { ViewEwayDashboardResolverService } from './shared/services/resolver/view-eway-dashboard-resolver.service';
import { ViewEwayExpiredResolverService } from './shared/services/resolver/view-eway-expired-resolver.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/tabs/tabs.module').then(m => m.TabsModule)
  },
  {
    path: 'auth/registration',
    loadChildren: () => import('./auth/setpassword/setpassword.module').then(m => m.SetpasswordModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./auth/forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule)
  },
  {
    path: 'smallogin',
    loadChildren: () => import('./auth/smal-login/smal-login.module').then(m => m.SmalLoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./auth/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'two-fa',
    loadChildren: () => import('./two-fa/two-fa.module').then(m => m.TwoFaModule)
  },
  {
    path: 'two-fa/show-qr-code',
    loadChildren: () => import('./two-fa/showqrcode/showqrcode.module').then(m => m.ShowqrcodeModule)
  },
  {
    path: 'verify-mfa-otp',
    loadChildren: () => import('./auth/verify-mfa-otp/verify-mfa-otp.module').then(m => m.VerifyMfaOtpModule)
  },
  {
    path: 'my-eway-bills',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/my-eway-bills/my-eway-bills.module').then(m => m.MyEwayBillsModule)
  },
  {
    path: 'part-bpending',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/part-bpending/part-bpending.module').then(m => m.PartBpendingModule)
  },
  {
    path: 'local-ewbs',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/local-ewbs/local-ewbs.module').then(m => m.LocalEwbsModule)
  },
  {
    path: 'part-bpending12-days',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/part-bpending12-days/part-bpending12-days.module').then(m => m.PartBpending12DaysModule)
  },
  {
    path: 'expiring-today',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/expiring-today/expiring-today.module').then(m => m.ExpiringTodayModule)
  },
  {
    path: 'add-godown',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./businessManagement/add-godown/add-godown.module').then(m => m.AddGodownModule)
  },
  {
    path: 'add-gstin',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./businessManagement/add-gstin/add-gstin.module').then(m => m.AddGstinModule)
  },
  {
    path: 'godown-list',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./businessManagement/godown-list/godown-list.module').then(m => m.GodownListModule)
  },
  {
    path: 'gstin-list',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./businessManagement/gstin-list/gstin-list.module').then(m => m.GstinListModule)
  },
  {
    path: 'view-eway/:type/:id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/view-eway/view-eway.module').then(m => m.ViewEwayModule)
  },
  {
    path: 'view-eway/:type',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/view-eway/view-eway.module').then(m => m.ViewEwayModule)
  },
  {
    path: 'gstin-header',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/gstin-header/gstin-header.module').then(m => m.GstinHeaderModule)
  },
  {
    path: 'user-profile',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./businessManagement/user-profile/user-profile.module').then(m => m.UserProfileModule)
  },
  {
    path: 'header',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/header/header.module').then(m => m.HeaderModule)
  },
  {
    path: 'user-settings',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./businessManagement/user-settings/user-settings.module').then(m => m.UserSettingsModule)
  },
  {
    path: 'view-eway-dashboard',
    canActivate: [AuthGuardService],
    resolve: { ewbCount: ViewEwayDashboardResolverService },
    loadChildren: () => import('./shared/components/view-eway-dashboard/view-eway-dashboard.module').then(m => m.ViewEwayDashboardModule)
  },
  {
    path: 'view-eway-expiry',
    canActivate: [AuthGuardService],
    resolve: { ewbCount: ViewEwayExpiredResolverService },
    loadChildren: () => import('./shared/components/expire-dashboard/view-eway-expiry.module').then(m => m.ViewEwayExpiryModule)
  },
  {
    path: 'view-eway-expiry-yesterday',
    canActivate: [AuthGuardService],
    resolve: { ewbCount: ViewEwayExpiredResolverService },
    loadChildren: () => import('./shared/components/expire-yesterday/expire-yesterday.module').then(m => m.ExpireYesterdayModule)
  },
  {
    path: 'footer-bar',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/footer-bar/footer-bar.module').then(m => m.FooterBarModule)
  },
  {
    path: 'widgets',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
  },
  {
    path: 'ewb-details/:ewbId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/ewb-details/ewb-details.module').then(m => m.EwbDetailsModule)
  },
  {
    path: 'ewb-details-no/:ewbId/:ewbNo',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/ewb-details/ewb-details.module').then(m => m.EwbDetailsModule)
  },
  {
    path: 'ewb-details-no/:ewbId/:ewbNo/:transId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/ewb-details/ewb-details.module').then(m => m.EwbDetailsModule)
  },
  {
    path: 'qrscan',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./QRScan/qrscan.module').then(m => m.QRScanModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'reset-password/:userid',
    loadChildren: () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'select-organisation',
    loadChildren: () => import('./select-organisation/select-organisation.module').then(m => m.SelectOrganisationModule),
    pathMatch: 'full'
  },
  {
    path: 'view-ceway',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/view-ceway/view-ceway.module').then(m => m.ViewCewayModule)
  },
  {
    path: 'cewb-details/:cewb/:transId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/cewb-details/cewb-details.module').then(m => m.CewbDetailsModule)
  },
  {
    path: 'cewb-details/:cewb',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/cewb-details/cewb-details.module').then(m => m.CewbDetailsModule)
  },
  {
    path: 'add-organisation',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./add-organisation/add-organisation.module').then(m => m.AddOrganisationModule)
  },
  {
    path: 'ewb-details-by-no/:ewbNo',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./shared/components/ewb-details-by-no/ewb-details-by-no.module').then(m => m.EwbDetailsByNoModule)
  },
  {
    path: 'generate',
    loadChildren: () => import('./generate-ewb/generate-ewb.module').then(m => m.GenerateEwbModule)
  },
  {
    path: 'initiate-multi-vehicle/:ewbNo/:ewbId/:transId',
    loadChildren: () => import('./actions/initiate-multi-vehicle/initiate-multi-vehicle.module').then(m => m.InitiateMultiVehicleModule)
  },
  {
    path: 'initiate-multi-vehicle/:ewbNo/:ewbId',
    loadChildren: () => import('./actions/initiate-multi-vehicle/initiate-multi-vehicle.module').then(m => m.InitiateMultiVehicleModule)
  },
  {
    path: 'edit-nic/:gstin',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./businessManagement/edit-nicmodal/edit-nicmodal.module').then(m => m.EditNicmodalModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./shared/components/hepler/hepler.module').then(m => m.HeplerModule)
  },
  {
    path: 'reports',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'reports/:type',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [ViewEwayDashboardResolverService, ViewEwayExpiredResolverService]
})
export class AppRoutingModule { }
