import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BusinessManagementService } from '../../shared/services/business-management.service';
import { take } from 'rxjs/operators';
import { WidgetsModule } from '../widgets.module';
import { WidgetsService } from '../widgets.service';
import { Router, ActivatedRoute } from '@angular/router';
// import { element } from 'protractor';
import { LoaderService } from '../../shared/services/loader.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { REGEX } from 'src/app/_models/constant';
import { ToasterService } from 'src/app/shared/services/toaster.service';
@Component({
  selector: 'app-watch-list-crud',
  templateUrl: './watch-list-crud.component.html',
  styleUrls: ['./watch-list-crud.component.scss']
})
export class WatchListCrudComponent {
  criteriaForm!: FormGroup;
  optionFlag: boolean = true;
  inputType!: String;
  selectedValue: any;
  label: any = null;
  criteriaValue: any = [];
  criteriaList: any = [];
  stateList: any = [];
  yesterdayDate = moment(new Date()).add(-1, 'days');
  minDate = new Date(+this.yesterdayDate.year(), +this.yesterdayDate.month(), +this.yesterdayDate.date());
  myDatepicker: any;
  consignerList = [
    { value: "transId", key: "Transporter Id" },
    { value: "fromGstin", key: "Gstin of Consigner" },
    { value: "fromPincode", key: "Pincode of Dispatch to Party" },
    { value: "actFromStateCode", key: "Dispatch State Code" },
    { value: "actToStateCode", key: "Delivery State Code" },
    { value: "toGstin", key: "Gstin of Consignee" },
    { value: "toPincode", key: "Pincode of ship to party" },
    { value: "genGstin", key: "Generator Gstin" },
    { value: "status", key: "Ewb Status" },
    // { value: "totInvValue", key: "Total Invoice Value" },
    { value: "validUpto", key: "Valid Upto" },
    { value: "vehicleNo", key: "Vehicle No" },
    // { value: "ewbDt", key: "Eway Bill Generation Date" },
    { value: "transDocNo", key: "Transport Document No" },
    { value: "actualDist", key: "Distance of transportation" }
  ]
  operatorList = [
    { value: "eq", key: "==" },
    { value: "in", key: "is one of" },
    { value: "lt", key: "<" },
    { value: "gt", key: ">" },
    { value: "lte", key: "<=" },
    { value: "gte", key: ">=" }
  ]
  statusList!: { key: string; value: string; }[];
  activeGSTIN!: {};
  id: any = null;
  dataToEdit = {
    name: '',
    query: {
      operator: '',
      criterias: []
    }
  };
  flag: boolean = false;
  editForm: boolean = false;
  criteriaData: any = {
    p: '',
    o: '',
    v: ''
  };
  query = {
    operator: '',
    criterias: []
  }
  editedValue = {
    id: '',
    name: '',
    query: {}
  };
  watchlistName!: FormGroup;
  tempVar: any;
  loopFlag = true;
  tempList: any[] = [];
  inOperator: boolean = true;
  multipleflag: boolean = true;
  gstins: any;
  autoCall: boolean = true;
  selectFlag: boolean = true;
  regex: { gstin: string; vehicle: string; decimal: string; decimalToTwo: RegExp; };
  activeBusinessSubscription1: any;
  activeBusinessSubscription2: any;
  activeBusinessSubscription3: any;
  constructor(
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private BMS: BusinessManagementService,
    private WS: WidgetsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loading: LoaderService,
    private datePipe: DatePipe
  ) {
    this.regex = REGEX;
    this.gstins = [];
  }

  ngOnInit() {
    this.initializeForm();
    this.criteriaList = [];
    this.BMS.fetchGstinList().subscribe((response) => {
      this.gstins = response;
    });

    this.id = +this.activatedRoute.snapshot.params['id'];

    if (this.id) {
      this.watchListDetail(this.id);
    } else {
      this.flag = true;
    }

  }

  initializeForm(watchLisName?: any) {
    this.watchlistName = new FormGroup({
      name: new FormControl((watchLisName ? watchLisName : this.dataToEdit.name), [Validators.required]),
    });
    this.criteriaForm = this.formBuilder.group({
      p: [this.criteriaData.p, [Validators.required]],
      o: [this.criteriaData.o, [Validators.required]],
      v: [this.criteriaData.v, [Validators.required]],
    });
  }

  watchListDetail(id: any) {
    this.activeBusinessSubscription1 = this.BMS.getActiveBusiness().pipe(
      take(1),
    ).subscribe((active) => {
      if (active === null) {
        this.toaster.warning('Invalid GSTIN');
      } else {
        this.WS.fetchWatchList(active.gstn).subscribe((response: any) => {
          if (response.status === 1) {
            let data = response.response;
            data.forEach((item: any) => {
              if (item.id === id) {
                this.dataToEdit = item;
                if (this.dataToEdit.query.hasOwnProperty('criterias')) {
                  this.tempList = this.dataToEdit.query.criterias;
                  this.consignerList.forEach((element, index) => {
                    this.tempList.forEach((data, index1) => {
                      if (data.p === element.value) {
                        this.operatorList.forEach((item) => {
                          if (item.value === data.o) {
                            this.criteriaList.push({
                              p: element.key,
                              o: item.key,
                              v: data.v
                            });
                            this.flag = true;
                            this.editForm = true;
                          }
                        })
                      }
                    })
                  })
                }
                this.initializeForm();
              }
            });
          }

        });
      }
    });
  }

  editCriteria(data: any) {
    let date = data.v.split('/');
    this.selectFlag = false;
    this.criteriaList.forEach((item: any, index: any) => {
      if (data.p === item.p && data.v === item.v) {
        this.criteriaList.splice(index, 1);
      }
    });
    this.consignerList.forEach((element, index) => {
      if (element.key === data.p) {
        this.operatorList.forEach(item => {
          if (item.key === data.o) {
            if (data.p === 'Valid Upto') {
              const value = new Date(date[0], date[1] - 1, date[2]);
              // data.v = new Date(date[2], date[1], date[0]);
              this.criteriaData.v = value;
            } else {
              this.criteriaData.v = data.v;
            }
            this.criteriaData.p = element.value;
            this.criteriaData.o = item.value;
            // this.criteriaData.v = data.v;
          }
        })
      }
    })
    if (this.tempList.length !== 0) {
      this.tempList.forEach((item, index) => {
        this.consignerList.forEach((element, index1) => {
          if (data.p === element.key && data.v === item.v) {
            this.tempList.splice(index, 1);
          }
        })
      });
    }
    this.initializeForm(this.watchlistName.value.name);
  }

  selectionChanged(e: any) {
    this.selectedValue = e.value;

    if (this.selectedValue === 'fromGstin') {
      this.optionFlag = true;
      this.label = 'Gstin';
      this.inputType = 'text';
    } else if (this.selectedValue === 'fromPincode') {
      this.optionFlag = true;
      this.label = 'Pincode';
      this.inputType = 'text';
    } else if (this.selectedValue === 'actFromStateCode') {
      this.optionFlag = true;
      this.label = 'State Code';
      this.inputType = 'text';
      this.stateList = this.BMS.stateListData();
    } else if (this.selectedValue === 'toGstin') {
      this.optionFlag = true;
      this.label = 'Gstin';
      this.inputType = 'text';
    } else if (this.selectedValue === 'toPincode') {
      this.optionFlag = true;
      this.label = 'Pincode';
      this.inputType = 'text';
    } else if (this.selectedValue === 'actToStateCode') {
      this.optionFlag = true;
      this.label = 'State Code';
      this.inputType = 'text';
      this.stateList = this.BMS.stateListData();
    } else if (this.selectedValue === 'status') {
      this.optionFlag = true;
      this.label = 'Status';
      this.inputType = 'text';
      this.statusList = [
        { key: 'Active', value: 'ACTIVE' },
        { key: 'Only Part A', value: 'ACTIVE_INVALID' },
      ]
    } else if (this.selectedValue === 'transId') {
      this.optionFlag = true;
      this.label = 'Transporte Id';
      this.inputType = 'text';
    } else if (this.selectedValue === 'genGstin') {
      this.optionFlag = true;
      this.label = 'Gstin';
      this.inputType = 'text';
    } else if (this.selectedValue === 'totInvValue') {
      this.optionFlag = false;
      this.label = 'Invoice Value';
      this.inputType = 'number';
      this.inOperator = false;
    } else if (this.selectedValue === 'validUpto') {
      this.optionFlag = false;
      this.label = 'Date';
      this.inputType = 'date';
      this.inOperator = false;
    } else if (this.selectedValue === 'ewbDt') {
      this.optionFlag = false;
      this.label = 'Date';
      this.inputType = 'date';
      this.inOperator = false;
    } else if (this.selectedValue === 'actualDist') {
      this.optionFlag = false;
      this.label = 'Distance of Transportation';
      this.inputType = 'number';
      this.inOperator = false;
    } else if (this.selectedValue === 'vehicleNo') {
      this.optionFlag = true;
      this.label = 'Vehicle No';
      this.inputType = 'text';
      this.inOperator = true;
    } else if (this.selectedValue === 'transDocNo') {
      this.optionFlag = true;
      this.label = 'Transport Document No';
      this.inputType = 'text';
      this.inOperator = true;
    }
  }

  operatorSelection(e: any) {
    let operatorValue = e.detail.value;
    if (this.label === 'Date') {
      this.multipleflag = false;
      this.criteriaForm.controls["v"].setValidators([Validators.required]);
      this.criteriaForm.updateValueAndValidity({ onlySelf: true });
    } else if (this.label === 'Vehicle No') {
      if (operatorValue === 'in') {
        this.criteriaForm.controls["v"].setValidators([Validators.required]);
      } else {
        this.criteriaForm.controls["v"].setValidators([Validators.required, Validators.pattern(`[a-zA-Z]{2}[0-9]{1,2}[a-zA-Z]{1,2}[0-9]{1,5}`)]);
      }
      this.criteriaForm.updateValueAndValidity({ onlySelf: true });
      if (operatorValue === 'eq') {
        this.multipleflag = false;
      } else {
        this.multipleflag = true;
      }
    } else if (operatorValue === 'eq') {
      this.multipleflag = false;
      this.criteriaForm.controls["v"].setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9._-]+$')]);
      this.criteriaForm.updateValueAndValidity({ onlySelf: true });
    } else {
      this.multipleflag = true;
      if (this.selectedValue === 'fromPincode' || this.selectedValue === 'Pincode') {
        this.criteriaForm.controls["v"].setValidators([Validators.required, Validators.pattern('^([0-9]|[^.$\\w])*$')]);
        // ^[0-9,]{1,50}$

      } else {
        this.criteriaForm.controls["v"].setValidators([Validators.required]);
      }
      this.criteriaForm.updateValueAndValidity({ onlySelf: true });
    }
  }

  addData() {
    if (this.selectFlag && this.editForm) {
      this.selectFlag = false;
      return;
    }
    if (this.criteriaForm.valid) {
      if (this.criteriaForm.value.p === 'vehicleNo') {
        if (this.criteriaForm.value.o === 'in') {
          this.criteriaForm.value.v = this.criteriaForm.value.v.split(',');
          this.criteriaForm.value.v.forEach((element: any, index: any) => {
            this.criteriaForm.value.v[index] = element.toUpperCase();
          });
          this.criteriaForm.value.v = this.criteriaForm.value.v.join(',')
        } else {
          this.criteriaForm.value.v = this.criteriaForm.value.v.toUpperCase();
        }
      }
      if (this.criteriaForm.value.p === 'validUpto') {
        this.criteriaForm.value.v = this.datePipe.transform(this.criteriaForm.value.v, 'yyyy/MM/dd');
      }
      if (Array.isArray(this.criteriaForm.value.v)) {
        this.criteriaForm.value.v = this.criteriaForm.value.v.join(',');
      }
      this.criteriaValue.push(this.criteriaForm.value);
      this.criteriaValue.push(
        {
          "p": "archived",
          "o": "eq",
          "v": "0"
        });
      if (this.autoCall) {
        this.consignerList.forEach((element, index) => {
          if (this.criteriaForm.value.p === element.value) {
            this.operatorList.forEach((item) => {
              if (item.value === this.criteriaForm.value.o) {
                this.criteriaList.push({
                  p: element.key,
                  o: item.key,
                  v: this.criteriaForm.value.v
                });
                // this.consignerList.splice(index, 1);
              }
            })
          }
        })
        this.label = '';
        this.inputType = 'text';
        this.criteriaForm.reset();
      }
      this.toaster.success('Data added !');
    } else {
      this.toaster.warning('Invalid Values !');
    }
  }

  addWatchlist() {


    if (this.criteriaForm.valid && this.criteriaValue.length === 0) {
      this.autoCall = false;
      this.addData();
    }
    if (this.criteriaValue.length !== 0 && this.watchlistName.valid) {
      this.activeBusinessSubscription2 = this.BMS.getActiveBusiness().pipe(
        take(1),
      ).subscribe((active) => {
        if (active === null) {

          this.toaster.warning('Pls Select Gstin !');
        } else {
          this._setOperator();
          this.query.criterias = this.criteriaValue;
          this.watchlistName.value.query = this.query;
          this.WS.addWatchList(this.watchlistName.value, active).subscribe((response: any) => {

            this.resetForms();
            this.watchlistName.reset();
            if (response.status === 1) {
              this.toaster.success(`${response.message}`);
              this.router.navigate(['watch-list']);
            } else {
              this.toaster.warning(`${response.errorList[0]}`);
            }
          }, (e) => {

          });
        }
      }, (e) => {

      });
    } else {

      if (this.watchlistName.invalid) {
        this.toaster.warning('Pls. add Watchlist name !');
      } else {
        this.toaster.warning('Pls. add Criteria!');
      }
    }
  }

  _setOperator() {
    this.criteriaValue.forEach((item: any, index: any) => {
      if (index === 0) {
        if (this.criteriaValue.length === 1) {
          this.query.operator = 'and'
        } else {
          this.tempVar = item.p;
        }
      } else if (this.loopFlag) {
        if (this.tempVar !== item.p) {
          this.query.operator = 'and'
          this.loopFlag = false;
        } else {
          this.query.operator = 'or'
        }
      }
    });
  }

  edit_Criteria() {

    if (this.criteriaForm.valid && this.criteriaValue.length === 0) {
      this.autoCall = false;
      this.addData();
    }
    if (this.tempList.length !== 0) {
      this.settingPrevoiusGodownsValue();
    }
    if (this.criteriaValue.length !== 0 && this.watchlistName.valid) {
      this.activeBusinessSubscription3 = this.BMS.getActiveBusiness().pipe(
        take(1),
      ).subscribe((active) => {
        if (active === null) {
          // this.activeGstin = false;

          this.toaster.warning('Pls Select Gstin !');
        } else {
          this._setOperator();
          this.editedValue.name = this.watchlistName.value.name;
          this.query.criterias = this.criteriaValue;
          this.editedValue.id = this.id;
          this.editedValue.query = this.query;
          this.WS.editWatchList(this.editedValue, active.gstn, this.id).subscribe((response: any) => {

            this.resetForms();
            if (response.status === 1) {
              this.toaster.success(`${response.message}`);
              this.router.navigate(['watch-list']);
            } else {
              this.toaster.warning(`${response.errorList[0]}`);
            }
          }, (e) => {

          });
        }
      });
    } else {

      if (this.watchlistName.invalid) {
        this.toaster.warning('Pls. add Watchlist name !');
      } else {
        this.toaster.warning('Pls. add Criteria!');
      }
    }
  }

  settingPrevoiusGodownsValue() {
    if (this.tempList && this.tempList.length !== 0) {
      this.tempList.forEach((element, index) => {
        this.consignerList.forEach((element1, index1) => {
          if (element.p === element1.value) {
            this.operatorList.forEach((item) => {
              if (item.value === element.o) {
                this.criteriaValue.push({
                  p: element1.value,
                  o: item.value,
                  v: element.v
                });
              }
            })
          }
        })
      })
    }
  }


  remove_criteria(obj: any) {
    this.criteriaList.forEach((item: any, index: any) => {
      this.consignerList.forEach((item1, index1) => {
        if (obj.p === item1.key && obj.v === item.v) {
          obj.p = item1.value;
          this.criteriaList.splice(index, 1);
          this.criteriaValue.forEach((item2: any, index2: any) => {
            if (obj.p === item2.p && obj.v === item2.v) {
              this.criteriaValue.splice(index2, 1);
            }
          })
        }
      })
    });
    if (this.tempList.length !== 0) {
      this.tempList.forEach((item, index) => {
        if (obj.p === item.p && obj.v === item.v) {
          this.tempList.splice(index, 1);
        }
      })
    }
    this.toaster.success('Criteria removed successfully !');
  }
  resetForms() {
    this.criteriaList = [];
    this.criteriaValue = [];
    this.tempList = [];
    this.autoCall = true;
    this.selectFlag = true;
    this.watchlistName.reset();
    this.criteriaForm.reset();
    this.criteriaData = {
      p: '',
      o: '',
      v: ''
    }
    this.dataToEdit = {
      name: '',
      query: {
        operator: '',
        criterias: []
      }
    };
  }

  ngOnDestroy() {
    if (this.activeBusinessSubscription1) {
      this.activeBusinessSubscription1.unsubscribe();
    }
    if (this.activeBusinessSubscription2) {
      this.activeBusinessSubscription2.unsubscribe();

    }
    if (this.activeBusinessSubscription3) {
      this.activeBusinessSubscription3.unsubscribe();
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }
}
