<div class="w-100 m-0 p-0 centerDiv">
  <app-gstin-header *ngIf="isShowGstinHeader" [gstins]='gstins' (refreshData)="refreshData($event)" class="alignCenter"></app-gstin-header>
  <!-- <ng-template #firstStep>
    Select the GSTIN you want to work on from the dropdown here...
  </ng-template> -->
  <div class="row">
    <div class="row">
      <div class="card expText" >

        <div class="row" *ngIf="info?.expiringIn7Days">
          <span class="text-warning" *ngIf="userRole === 2">
            <h6>
              Your subscription expires in less than 7 days. Contact Admin
            </h6>
          </span>
          <span class="text-warning" *ngIf="userRole !== 2">
            <span
              [routerLink]="['/user-profile/user']"
              [queryParams]="{ show: 'subscription' }"
            >
              Your subscription expires in less than 7 days.
              <span class="text-decoration-underline"
                >Renew from My Profile</span
              >
            </span>
          </span>
        </div>
        <div class="row" *ngIf="info?.expiringToday">
          <div class="text-warning" *ngIf="userRole === 2">
            <span>Your subscription expires today. Contact Admin</span>
          </div>
          <div class="text-warning" *ngIf="userRole !== 2">
            <span
              [routerLink]="['/user-profile/user']"
              [queryParams]="{ show: 'subscription' }"
            >
              Your subscription expires today.
              <span class="text-decoration-underline"
                >Renew from My Profile</span
              >
            </span>
          </div>
        </div>
        <div class="row" *ngIf="info?.expired">
          <span class="text-warning" *ngIf="userRole === 2">
            <span>Your subscription has expired. Contact Admin</span>
          </span>
          <span class="text-warning" *ngIf="userRole !== 2">
            <span
              [routerLink]="['/user-profile/user']"
              [queryParams]="{ show: 'subscription' }"
            >
              Your subscription has expired.
              <span class="text-decoration-underline"
                >Renew from My Profile</span
              >
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="info?.actualcount > 0.9 * info?.count">
      <div class="card">
        <span class="text-warning">
          <h6>You have used 90% of subscribed usage.</h6>
        </span>
      </div>
    </div>
    <div class="row bgt" *ngIf="initialfetchdone && lastUpdatedCurrentTime">
      <div class="card mt10 boxshodow py-2">
        <div class="row">
          <div *ngIf="!isOrganisation">
            <div class="mb-1 lastupdate">
              Updated on: {{ lastUpdatedCurrentTime | date : "medium" }}
              <span *ngIf="!lastUpdatedCurrentTime">NA</span>
              <span>
                <button
                  mat-raised-button
                  *ngIf="currentUpdateFailed && userRole !== 2"
                  class="text-warning warning bgWhite mr10"
                  [routerLink]="['/dashboard/stats']"
                >
                  Last Update Failed.Check Scheduler Stats
                </button>
                <button
                  class="text-warning warning bgWhite mr10"
                  mat-raised-button
                  *ngIf="currentUpdateFailed && userRole === 2"
                >
                  Last Update Failed. Contact Admin
                </button>
              </span>
              <span *ngIf="scheduler3Failed">  <!--*ngIf="scheduler3Failed" -->
                <button
                  mat-raised-button
                  (click)="getFailedEWBs()"
                  class="text-warning warning bgWhite mr10"
                >
                  Auto Extension Failed. Check EWBs
                </button>
              </span>
            </div>
            <div class="lastupdate">
              Last Historical Update on: {{ lastUpdatedHistoricalTime | date : "medium" }}
              <span *ngIf="!lastUpdatedHistoricalTime">NA</span>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        (click)="viewEwayBillDashboard()"
        class="card my-2 bgt border-0 noPadding cursor-pointer"
        *ngIf="initialfetchdone"
      >
        <div class="d-flex justify-content-between border-0 card-text">
          <div class="col d-flex">
            <div class="cardBg w140 text-center h-100 noradius" *ngIf="count">
              <p class="fa-20 text-white text-center numTile">
                {{
                  count?.length > 0
                    ? count[2].response
                    : count.ewbCount?.length > 0
                    ? count.ewbCount[2].response
                    : "N"
                }}
              </p>
            </div>
            <div class="d-flex mx-2 flex-column">
              <h1 style="font-size: 16px; font-weight: 400;padding-left: 10px; ">My EWB</h1>
              <h3 style="font-size: 16px; font-weight: 400" class="lastupdate">
                All actionable EWBs
              </h3>
            </div>
          </div>
          <div
            class="col d-flex justify-content-end"
            *ngIf="
              userRole == 0 || userRole == 1 || userRole == 2 || isOrganisation
            "
          >
            <button
              class="bg-transparent border-0"
              mat-button
              (click)="download($event, 'MY_EWB')"
            >
              <mat-icon class="overflow-visible icon">get_app</mat-icon>
            </button>
          </div>
        </div>
        <div class="cardBg h-0 w-100 p-13 mt-1"></div>
      </div>
    </div>
    <div class="row" *ngIf="initialfetchdone">
      <div
        class="card expCard cursor-pointer"
        (click)="viewEwayBillExpiryYesterday('EWB_EXPIRED_YESTERDAY')"
      >
        <div class="col">
          <div class="row d-flex justify-content-between align-items-center">
            <div class="col">
              <span class="fs-6 fa-20 fw-medium expYesTexts"
                >Expired Yesterday</span
              >
            </div>
            <div class="col d-flex align-items-center justify-content-end">
              <span class="mx-3 dataN bgt">
                {{
                  count?.length > 0
                    ? count[10].response
                    : count.ewbCount?.length > 0
                    ? count.ewbCount[10].response
                    : "NoData"
                }}
              </span>
              <button
                (click)="download($event, 'EWB_EXPIRED_YESTERDAY')"
                *ngIf="
                  userRole == 0 ||
                  userRole == 1 ||
                  userRole == 2 ||
                  isOrganisation
                "
                class="bg-transparent border-0"
              >
                <mat-icon class="overflow-visible icon">get_app</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 d-flex noPadding" *ngIf="initialfetchdone">
      <div class="col">
        <ng-template #thirdStep>
          Ewaybills expiring today and those with Part B Pending update
        </ng-template>
        <div class="col">
          <div
            class="card rounded-4 shadow-lg cursor-pointer"
            (click)="viewEwayBillExpiry('EWB_EXPIRING_TODAY')"
          >
            <div class="card-title cardBg overflow-hidden">
              <p class="text-center text-white textTile">Expiring Today</p>
            </div>
            <div
              class="card-body p-0 m-0 d-flex flex-column justify-content-center align-item-center position-relative"
            >
              <div>
                <p *ngIf="count" class="text-center fa-20">
                  {{
                    count?.length > 0
                      ? count[0].response
                      : count.ewbCount?.length > 0
                      ? count.ewbCount[0].response
                      : "NoData"
                  }}
                </p>
                <button
                  class="position-absolute top-0 end-0 border-0 bg-transparent"
                  *ngIf="
                    userRole == 0 ||
                    userRole == 1 ||
                    userRole == 2 ||
                    isOrganisation
                  "
                  (click)="download($event, 'EWB_EXPIRING_TODAY')"
                  name="download"
                >
                  <mat-icon class="overflow-visible">get_app</mat-icon>
                </button>
              </div>
              <p class="lastupdate text-center">
                Available for extension from 4 p.m. today
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div
          class="card rounded-4 shadow-lg cursor-pointer"
          (click)="viewEwayBill('ONLY_PART_A_EWB')"
        >
          <div class="card-title cardBg">
            <p class="text-center text-white textTile">Pending Part B</p>
          </div>
          <div
            class="card-body m-0 p-0 d-flex flex-column justify-content-center align-item-center position-relative"
          >
            <button
              class="position-absolute top-0 end-0 border-0 bg-transparent"
              *ngIf="
                userRole == 0 ||
                userRole == 1 ||
                userRole == 2 ||
                isOrganisation
              "
              (click)="download($event, 'ONLY_PART_A_EWB')"
              name="download"
            >
              <mat-icon>get_app</mat-icon>
            </button>
            <p *ngIf="count" class="text-center fa-20">
              {{
                count?.length > 0
                  ? count[4].response
                  : count.ewbCount?.length > 0
                  ? count.ewbCount[4].response
                  : "NoData"
              }}
            </p>
          </div>
          <div>
            <p class="lastupdate text-center">Only Part A EWBs</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex mt-2 noPadding" *ngIf="initialfetchdone">
      <div class="col">
        <div
          class="card m-0 rounded-4 shadow-lg cursor-pointer"
          (click)="viewEwayBill('PARTB_NOT_UPDATED_FOR_12D')"
        >
          <div class="card-title cardBg">
            <p class="text-center text-white textTile">Pending Part B</p>
          </div>
          <div
            class="card-body m-0 p-0 flex-column justify-content-center align-item-center position-relative"
          >
            <div class="col">
              <button
                class="position-absolute top-0 end-0 border-0 bg-transparent"
                *ngIf="
                  userRole == 0 ||
                  userRole == 1 ||
                  userRole == 2 ||
                  isOrganisation
                "
                (click)="download($event, 'PARTB_NOT_UPDATED_FOR_12D')"
                name="download"
              >
                <mat-icon>get_app</mat-icon>
              </button>
              <p class="fa-20 text-center" *ngIf="count">
                {{count?.length > 0 ? count[1].response: (count.ewbCount?.length > 0 ? count.ewbCount[1].response: "No Data")}}
              </p>
            </div>
            <div class="col">
              <p class="lastupdate text-center">For more than 12 days</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div
          class="card shadow-lg rounded-4 cursor-pointer"
          (click)="viewEwayBill('EWB_WITHIN_200KM')"
        >
          <div class="card-title cardBg">
            <p class="text-center text-white textTile">Local EWBs</p>
          </div>
          <div
            class="card-body m-0 p-0 d-flex flex-column justify-content-center align-item-center position-relative"
          >
            <div class="col">
              <button
                class="position-absolute top-0 end-0 border-0 bg-transparent"
                *ngIf="
                  userRole == 0 ||
                  userRole == 1 ||
                  userRole == 2 ||
                  isOrganisation
                "
                (click)="download($event, 'EWB_WITHIN_200KM')"
                name="download"
              >
                <mat-icon>get_app</mat-icon>
              </button>
              <p class="fa-20 text-center" *ngIf="count">
                {{
                  count?.length > 0
                    ? count[3].response
                    : count.ewbCount?.length > 0
                    ? count.ewbCount[3].response
                    : "NoData"
                }}
              </p>
            </div>
            <p class="lastupdate text-center">Distance less than 200 kms</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 flex-column d-flex noPadding mb65">
      <div class="col" *ngIf="initialfetchdone">
        <div
          class="card my-2 bgt border-0 noPadding noradius cursor-pointer"
          (click)="viewCEwayBill()"
        >
          <div class="row d-flex">
            <div class="col-3">
              <div
                class="d-flex justify-content-center align-items-center cardBg h-100 noradius"
              >
                <p class="text-center fa-20 text-white numTile" *ngIf="count">
                  {{
                    count?.length > 0
                      ? count[5] && count[5]?.response
                      : count.ewbCount?.length > 0
                      ? count.ewbCount[5]?.response
                      : "N"
                  }}
                </p>
              </div>
            </div>
            <div class="col-9">
              <h1 style="font-size: 16px; font-weight: 400;padding-left: 10px; ">Consolidated EWB</h1>              
              <h3 class="lastupdate" style="font-size: 16px; font-weight: 400;">All tripsheet EWBs</h3>
            </div>
          </div>
        </div>
        <div class="cardBg h-0 w-100 p-13 mt-1"></div>
      </div>

      <div class="col" *ngIf="initialfetchdone">
        <div class="card mt-2 border-0">
          <div class="row" class="updateBlock" *ngIf="isSchedulerInProgress">
            <div class="cardBg w-100 mx-2"></div>
            <div class="col px-3 text-center">
              <p class="m-0 p-0">
                Data fetch is in progress. Expected completion time is:
                {{ schedulerTime | date : "medium" }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="text-center" *ngIf="noActiveGstin && gstins?.length != 0">
          <h4
            *ngIf="parentOrganisationSeleced.sysadmin == 1"
            class="selectOrgLbl"
          >
            Select an Organisation or a GSTIN/ TransId from the above.
          </h4>
          <h4 *ngIf="parentOrganisationSeleced.sysadmin == 0">
            Select a GSTIN from the above.
          </h4>
        </div>
      </div>
      <div class="col" *ngIf="!initialfetchdone">
        <div class="card py-3 border-0">
          <span *ngIf="gstins?.length === 0">
            <button mat-raised-button color="primary" (click)="toAddGstin()">
              Add GSTIN
            </button>
            to continue.
          </span>
          <span
            *ngIf="!noActiveGstin && gstins?.length != 0 && !isOrganisation"
          >
            <h4>Initial fetch in progress</h4>
          </span>
          <div *ngIf="(userRole == 0 || userRole == 1) && !noActiveGstin">
            <button>
              <mat-icon>get_app</mat-icon>
            </button>
          </div>
        </div>
      </div>
     <span *ngIf="(userRole == 0 || userRole == 1) && !noActiveGstin ">
      <a
        *ngIf="initialfetchdone"
        class="position-absolute posit"
        mat-fab
        (click)="generateEWB()"
      >
        <mat-icon class="m-0 p-0 clrWhite">add</mat-icon>
      </a>
      </span>
    </div>
  </div>
</div>
<app-footer-bar></app-footer-bar>