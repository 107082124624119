import { Injectable } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { BusinessManagementService } from '../business-management.service';
import { EwbDetailsService } from '../ewb-details.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardResolverService {

  constructor(private backend: DashboardService, private activeBusiness: BusinessManagementService, 
    private ewbDetailsService : EwbDetailsService) {}

  activeGstin = false;
  activeBus: any;
  isOrganisation: any;

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    this.activeBusiness.getActiveBusiness().subscribe((active) => {
      if (active === null) { this.activeGstin = false; } else {this.activeGstin = true; this.activeBus = active; this.isOrganisation = active.isOrganisation }
    });
    if (this.activeGstin && !this.isOrganisation) {
      return this.backend.getData(this.activeBus);
    }else if(this.activeGstin && this.isOrganisation){
      return this.ewbDetailsService.countOrganisationEWB()
    }
  }
}
