import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

import { ToasterService } from './toaster.service';
import { forkJoin, of } from 'rxjs';
import { InvoiceDetailSchedularModel } from 'src/app/_models/businessManagement/InvoiceDetailScheduler.model';

@Injectable({
  providedIn: 'root'
})
export class EwbDetailsService {

  constructor(
    private http: HttpClient,
    private toaster: ToasterService,
  ) { }

  fetchEwbDetails(ewbId: number, active: any) {
    return this.http.get(`${environment.apiUrl}/ewb/ewbById?ewbId=${ewbId}&gstin=${active}`)
      .pipe(map((response: any) => {
        if (response.status !== 0) {
          return response.response;
        } else {
          this.toaster.warning(response.message);
          return response;
        }
      }));
  }
  fetchExpTdyFastag(ewbNo: number, active: any) {
    return this.http.get(`${environment.apiUrl}/logilocker/fastag/location?ewbNo=${ewbNo}&gstin=${active}`)
      .pipe(map((response: any) => {
        return response;
      }));
  }


  fetchEwbDetailsByEWBNo(ewbNo:any, active:any) {
    return this.http.get(`${environment.apiUrl}/ewb/data?ewbNo=${ewbNo}&gstin=${active}`).pipe(map((response: any) => {
      return response;
    }));
  }

  refreshEWBByNo(ewbNo:any, active:any) {
    return this.http.get(`${environment.apiUrl}/ewb/refreshEwb?ewbNo=${ewbNo}&gstin=${active}`).pipe(map((response: any) => {
      return response;
    }));
  }
  refreshEWBByNoEwbDetails(ewbNo:any, active:any) {
    return this.http.get(`${environment.apiUrl}/ewb/refreshEwb?ewbNo=${ewbNo}&gstin=${active}`).pipe(map((response: any) => {
      if (response.status !== 0) {
        return response.response;
      } else {
        this.toaster.warning(response.message);
        return response;
      }
    }));
  }

  fetchCEwbDetails(cewbId:any, active:any) {
    return this.http.get(`${environment.apiUrl}/cewb/data?cewbId=${cewbId}&gstin=${active}`).pipe(map((response: any) => {
      if (response.status !== 0) {
        return response.response;
      }
    }));
  }

  fetchEWBbyTransDocNumber(transNo:any, gstn:any) {
    return this.http.get(`${environment.apiUrl}/search/trnNo?trnNo=${transNo}&gstin=${gstn}`).pipe(map((response: any) => {
      return response;
    }));
  }
  fetchdetailsByGstin(data:any, gstn:any) {
    return this.http.get(`${environment.apiUrl}/search/gstin?gstinToSearch=${data}&gstin=${gstn}`).pipe(map((response: any) => {
      return response;
    }));
  }
  fetchdetailsByTransporter(data:any, gstn:any) {
    return this.http.get(`${environment.apiUrl}/search/trnNo?trnNo=${data}&gstin=${gstn}&updateNeeded=true`).pipe(map((response: any) => {
      return response;
    }));
  }

  refreshCEWBByNo(ewbNo:any, active:any) {
    return this.http.get(`${environment.apiUrl}/cewb/refreshCEwb?cewbNo=${ewbNo}&gstin=${active}`).pipe(map((response: any) => {
      if (response.status !== 0) {
        return response.response;
      }
    }));
  }

  searchCEWBByNo(ewbNo:any, active:any) {
    return this.http.get(`${environment.apiUrl}/cewb/refreshCEwb?cewbNo=${ewbNo}&gstin=${active}`).pipe(map((response: any) => {
      return response;
    }));
  }
  searchCEWBByNoExt(ewbNo:any, active:any) {
    return this.http.get(`${environment.apiUrl}/cewb/ext?cewbNo=${ewbNo}&gstin=${active}`).
      pipe(map((response: any) => {
        return response;
      }));
  }

  countOrganisationEWB(){

    const response1 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'MY_EWB', defaultquery: null } );
    const response2 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'EWB_WITHIN_200KM', defaultquery: null });
    const response3 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'EWB_EXPIRING_TODAY', defaultquery: null });
    const response4 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'PARTB_NOT_UPDATED_FOR_12D', defaultquery: null });
    const response5 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'ONLY_PART_A_EWB', defaultquery: null });
    const response6 = this.http.post(`${environment.apiUrl}/cewb/count/org`, {"type": null,"page": 0,"size": 0,"defaultquery": null});
    const response7 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'EWB_EXPIRED_YESTERDAY', defaultquery: null });

      return forkJoin([response3, response4, response1, response2, response5, response6,of(null), of(null), of(null), of(null), response7]);
    }

    getEwayBillDashboard(gstins:any){
    const response1 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: '',
        page:"0",
        size:"100",
        defaultquery: { operator: 'and', "criterias":[
         {"p": "transId", "o":"in", "v":gstins,}, {"p":"archived","o":"in", "v":"0"}
       ]
    }});
    const response2 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: '',
      page:"0",
      size:"100",
      defaultquery: { operator: 'and', "criterias":[
       {"p": "genGstin", "o":"in", "v":gstins,}, {"p":"archived","o":"in", "v":"0"}
   ]
}});
    const response3 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
     { type: '',
      page:"0",
      size:"100",
      defaultquery: { operator: 'and', "criterias":[
       {"p": "toGstin", "o":"in", "v":gstins,}, {"p":"archived","o":"in", "v":"0"}
   ]
}});
    const response4 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'MANUAL_ARCHIVE', defaultquery: null, page: 0, size: 10 });

      // const response5 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      // { type: 'TRANS_UPDATED', defaultquery: null, page: 0, size: 10 });

    return forkJoin([response1, response2, response3, response4]);

    }



    getEwbExpireYesterday(){
      const response1 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'EWB_EXPIRED_YESTERDAY', defaultquery: null, "page":0 , size:"10",sortfield:"ewbDate", sortDir:"desc","addlquery":{"operator":"and", "criterias":[{"p":"godownId", "o":"eq", "v":"-1"}]}});
      const response2 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'EWB_EXPIRED_YESTERDAY', defaultquery: null, "page":0 , size:"10",sortfield:"ewbDate", sortDir:"desc","addlquery":{"operator":"and", "criterias":[{"p":"godownId", "o":"neq", "v":"-1"}]}});
      return forkJoin([response1, response2]);
    }

    getEwbExpireToday(){

      const response1 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'EWB_EXPIRING_TODAY', defaultquery: null, "page":0 , size:"10",sortfield:"ewbDate", sortDir:"desc","addlquery":{"operator":"and", "criterias":[{"p":"godownId", "o":"eq", "v":"-1"}]}});
      const response2 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'EWB_EXPIRING_TODAY', defaultquery: null, "page":0 , size:"10",sortfield:"ewbDate", sortDir:"desc","addlquery":{"operator":"and", "criterias":[{"p":"godownId", "o":"neq", "v":"-1"}]}});
      const response3 = this.http.post(`${environment.apiUrl}/org/ewb/count`,
      { type: 'MANUAL_ARCHIVE', defaultquery: null, "page":0 , size:"10",sortfield:"ewbDate", sortDir:"desc","addlquery":{"operator":"and", "criterias":[{"p":"validUpto", "o":"eq", "v":"TODAY"}]}});
      
      return forkJoin([response1, response2, response3]);
    }

    schedulerInvoiceDetails(data : InvoiceDetailSchedularModel){
      return this.http.post(`${environment.apiUrl}/scheduler/invdetails`, data).pipe(map((response: any) => {
        return response;
      }));
    }


    getOrganisationCount(data:any){
     return this.http.post(`${environment.apiUrl}/org/ewb/count`, data);

    }

    saveDHLData(gstin:any, data:any){
      return this.http.post(`${environment.apiUrl}//ewb/dhlData?gstin=${gstin}`, data);
    }
}
