<div id="map" #mapCanvas class="map" style="width: 100%;height:  90.6%;"></div>
<div *ngIf="error" class="" >
    <div class="noDataFound d-flex justify-content-center align-items-center bg-light">
        <div class="modal-content  text-center p-4">
            <img src="http://100dayscss.com/codepen/alert.png" width="44" height="38" class="fastagImg" />
            <h5 class="mt-2">ops!</h5>
            <p>{{message ? message : 'Some error occurred. Please try again later.'}}</p>
        </div>
    </div>
    <div class="errorClose">
        <div class="button" (click)="dismiss()">Close</div>
    </div>
</div>
<div *ngIf="!error">
    <div class="button" (click)="dismiss()">Close</div>
</div>
<div *ngIf="refreshDataLoader" class="fetchdata">
    <div class="overlay d-flex justify-content-center align-items-center">
        <div class="spinner-border text-primary" role="status"></div>
        <div class="message-data ml-2">Fetching Location</div>
    </div>
</div>
<div id="popup" class="ol-popup">
    <a href="#" id="popup-closer" class="ol-popup-closer"></a>
    <div id="popup-content" style="width: 296px;"></div>
</div>