import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { HeaderComponent } from '../shared/components/header/header.component';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { GstinHeaderComponent } from '../shared/components/gstin-header/gstin-header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderModule } from '../shared/components/header/header.module';
import { HomeModule } from '../home/home.module';
import { GstinHeaderModule } from '../shared/components/gstin-header/gstin-header.module';
import { FooterBarModule } from '../shared/components/footer-bar/footer-bar.module';


@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatIconModule,
    MatTabsModule,
    MatSelectModule,
    MatMenuModule,
    MatFormFieldModule,
    HomeModule,
    HeaderModule,
    MatToolbarModule,
    FooterBarModule,
    GstinHeaderModule,
  ]
})
export class DashboardModule {
 }
