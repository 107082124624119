<span mat-dialog-title style="text-align: center">
  <h3>TERMS AND CONDITIONS</h3>
</span>
<mat-dialog-content class="overflow-x-auto">
  <div class="fw-bolder pt-3"> Terms for Use</div>
    <p>
      Please read these Terms of Use carefully before using this Web Site ("Web
      Site ") or Mobile Application ("Application"). These Terms of Use ("Terms
      of Use") govern your access to and use of the Web Site or Application. The
      Web Site or Application is available for your use only on the condition
      that you agree to the Terms of Use set forth below. If you do not agree to
      all of the Terms of Use, do not access or use the Web Site or Application.
      By accessing or using the Web Site or Application, you and the entity you
      are authorized to represent ("you" or "your") signify your agreement to be
      bound by the Terms of Use.
    </p>
    <div class="fw-bolder pt-3">User Eligibility</div>
    <p>
      The Web Site or Application is provided by “IRIS Logix Solutions” which
      means IRIS Logix Solutions and all group companies (including parent
      company & subsidiaries, including those which may get created in future).
      The use of the Web Site or Application is available only to entities and
      persons over the age of majority and who can form legally binding
      agreement(s) under applicable law.If you do not qualify, you are not
      permitted to use the Web Site or Application.
    </p>

    <div class="fw-bolder pt-3">Scope of Terms of Use </div>
    <p>
      These Terms of Use govern your use of the Web Site, Application and
      services (collectively, "Services") available via the Web Site or
      Application, except to the extent such Services are the subject of a
      separate agreement. Specific terms or agreements may apply to the use of
      certain Servicesand other items provided to you or Application ("Service
      Agreement(s)").
    </p>
    <div class="fw-bolder pt-3">Modifications</div>
    <p>
      IRIS Logix Solutions may revise and update these Terms of Use at any time.
      Your continued usage of the Web Site or Application after any changes to
      these Terms of Use will mean you accept those changes. Any aspect of the
      Web Site or Application may be changed, supplemented, deleted or updated
      without notice at the sole discretion of IRIS Logix Solutions. IRIS Logix
      Solutions may also change or impose fees for products and services
      provided through the Web Site or Application at any time in its sole
      discretion.
    </p>
    <div class="fw-bolder pt-3">IRIS Logix Solutions Privacy Policy</div>
    <p>
      IRIS Logix Solutions Privacy Policy governs the use of information
      collected from or provided by you at the Web Site or Application. With
      respect to any individual whose personal information is provided by you to
      IRIS Logix Solutions, you represent to IRIS Logix Solutions that you have
      obtained all necessary consents for the processing of such personal
      information contemplated by the Services you are using and by IRIS Logix
      Solutions Privacy Policy, including the transfer of such datato other
      countries whose laws may not provide the same level of protection for the
      personal data as the laws of the country of origin of such individual. The
      Privacy policy of parent company [<a
        href="https://www.irisgst.com/privacy-policy/"
        target="blank"
        >https://www.irisgst.com/privacy-policy/</a
      >] will also be applicable.
    </p>
    <div class="fw-bolder pt-3">License and Ownership</div>
    <p>
      Any and all intellectual property rights ("Intellectual Property")
      associated with the Web Site or Application and its contents ("Content")
      are the sole property of IRIS Logix Solutions. The Content isprotected by
      copyright and other laws in both, India and other countries. Elements of
      the Web Site or Application are also protected by trade secret, unfair
      competition, and other laws and shall not be copied or imitated in whole
      or in part.
    </p>
    <div class="fw-bolder pt-3">Restrictions on Use of the Web Site and Application</div>
    <p>
      In addition to other restrictions set forth in these Terms of Use, you
      agree that:
    </p>
    <ul>
      <li>
        You shall not disguise the origin of information transmitted through the
        Web Site or Application.
      </li>
      <li>
        You will not place false or misleading information on the Web Site or
        Application.
      </li>
      <li>
        You will not use or access any service or information available via the
        Web Site or Application in a manner not expressly permitted by IRIS
        Logix Solutions.
      </li>
      <li>
        You will not input or upload to the Web Site or Application any
        information which contains viruses, or other computer programming
        routines that are intended to damage, interfere with, intercept or
        expropriate any system, the Web Site, Application or Information or that
        infringesthe Intellectual Property (defined below) rights of another.
      </li>
      <li>
        Certain areas of the Web Site or Application are restricted to customers
        of IRIS Logix Solutions.
      </li>
      <li>
        You shall not use or access the Web Site or Application in any way that,
        in IRIS Logix Solutions’ judgment, adversely affects the performance or
        function of the IRIS Logix Solutions Systems, Services or the Web Site
        or Application.
      </li>
      <li>
        You shall not frame or utilize framing techniques to enclose any portion
        or aspect of the Content or the Information, without the express written
        consent of IRIS Logix Solutions.
      </li>
    </ul>
    <div class="fw-bolder pt-3">Advertisements and Promotions</div>
    <p>IRIS Logix Solutions
      may run advertisements and promotions from third parties on the Web Site
      or Application. Your correspondence or business dealings with, or
      participation in promotions of, advertisers other than IRIS Logix
      Solutions found on or through the Web Site or Application, including
      payment and delivery of related goods or services, and any other terms,
      conditions, warranties or representations associated with such dealings,
      are solely between you and such advertiser. IRIS Logix Solutions is not
      responsible or liable for any loss or damage of any sort incurred as the
      result of any such dealings or as the result of the presence of such
      advertisers on the Web Site or Application.
    </p>
    <div class="fw-bolder pt-3">Content Provided via Links</div>
    <p>
      You may find links to other Internet sites or resources on the Web Site or
      Application. You acknowledge and agree that IRIS Logix Solutions is not
      responsible for the availability of such external sites or resources and
      does not endorse and is not responsible or liable for any content,
      advertising, products, or other materials on or available from such sites
      or resources. IRIS Logix Solutions will not be responsible or liable,
      directly or indirectly, for any actual or alleged damage or loss caused by
      or in connection with use of or reliance on any such content, goods or
      services available on or through any such site or resource.
    </p>

    <div class="fw-bolder pt-3">Security</div>
    <p>
      You agree and understand that you are responsible for maintaining the
      confidentiality of passwords associated with any account you use to access
      the Services. Accordingly, you agree that you will be solely responsible
      to IRIS Logix Solutions for all activities that occur under your account.
      If you become aware of any unauthorized use of your password or of your
      account, you agree to notify IRIS Logix Solutions immediately.
    </p>

    <div class="fw-bolder pt-3">Termination</div>
    <p>
      You agree that IRIS Logix Solutions, in its sole discretion, may terminate
      or suspend your use of the Web Site or Application and its Content at any
      time and for any or no reason in its sole discretion, even if access and
      use continue to be allowed to others. Upon such suspension or termination,
      you must immediately
    </p>

    <ul>
      <li>discontinue use of the Web Site or Application</li>
      <li>
        destroy any copies you have made of any portion of the Content.
        Accessing the Web Site or Application,Information or Services after such
        termination, suspension or discontinuation shall constitute an act of
        trespass. Further, you agree that IRIS Logix Solutions shall not be
        liable to you or any third party for any termination or suspension of
        your access to the Web Site or Application. Further, any questions about
        refunds and cancellations should be addressed to support@easywaybill.in
      </li>
    </ul>
    <div class="fw-bolder pt-3">Limitation of Liability</div>
    <p>
      This Website/Application is not affiliated to Government of India. It is a
      connecting platform allowingusers to interact with the government system /
      databases in an easier manner. In No Event Shall IRIS Logix Solutions, Its
      Affiliates, Its Licensors, Its Suppliers or any Third Parties mentioned at
      the Web Site or Application be liable for any Financial, Incidental,
      Indirect, Exemplary, Punitive and Consequential Damages, Lost Profits,
      Lost Reputation, Lost Goodwill or Damages Resulting from Lost Data or
      Business Interruption resulting from the use of or inability to use or
      incorrect utilization of the Web Site or Application. Any ambiguous
      language in this terms and conditions shall be interpreted according to
      its fair meaning and shall not be construed for or against any party.
      Further, you expressly understand and agree that IRIS Logix Solutions, Its
      Affiliates, Its Licensors, ItsSuppliers or any Third Parties mentioned at
      the Web Site or Application shall not be liable to you for any loss or
      damage which may be incurred by you, including but not limited to loss or
      damage as a result of:
    </p>

    <ul>
      <li>
        Any reliance placed by you on the completeness,accuracy or existence of
        any advertising, or as a result of any relationship or transaction
        between you and any advertiser or sponsor whoseadvertising appears on
        the services;
      </li>
      <li>
        Any changes which IRIS Logix Solutions may make to the services,or for
        any permanent or temporary cessation in the provision of the services
        (or any features within the services);
      </li>
      <li>
        The deletion of, corruption of, or failure to store, any content and
        other communications data maintained or transmitted by or through your
        use of the services;
      </li>
      <li>
        Your failure to provide IRIS Logix Solutions with accurate account
        information;
      </li>
      <li>
        Your failure to keep your password or account details secure and
        confidential;The limitations on IRIS Logix Solutions’ liability to you
        shall apply whether or not IRIS Logix Solutions has been advised of or
        should have been aware of the possibility of any such losses arising.
      </li>
    </ul>

    <p>
      The limitations on IRIS Logix Solutions’ liability to you shall apply
      whether or not IRIS Logix Solutions has been advised of or should have
      been aware of the possibility of any such losses arising.
    </p>

    <div class="fw-bolder pt-3">Warranties</div>
    <p>
      You expressly understand and agree that
      your use of the Services is at your sole risk and that the services are
      provided "as is" and "as available". In particular, IRIS Logix Solutions,
      its subsidiaries and affiliates, and its licensors do not represent or
      warrant to you that:
    </p>

    <ul>
      <li>Your use of the Services will meet your requirements,</li>
      <li>
        Your use of the services will be uninterrupted, timely, secure or free
        from error,
      </li>
      <li>
        Any information obtained by you as a result of your use of the Services
        will be accurate or reliable, and
      </li>
      <li>
        That defects in the operation or functionality of any software provided
        to you as part of the Services will be corrected.
      </li>
    </ul>
    <p>
      Any material downloaded or otherwise obtained through the use of the
      Services is done at your own discretion and risk and that you will be
      solely responsible for any damage to your computer system or other device
      or loss of data that results from the download of any such material. No
      advice or information, whether oral or written, obtained by you from IRIS
      Logix Solutions or through or from the Services shall create any warranty
      not expressly stated in the terms. IRIS Logix Solutions further expressly
      disclaims all warranties and conditions of any kind, whether express or
      implied, including, but not limited to the implied warranties and
      conditions of merchantability, fitness for a particular purpose and
      non-infringement.
    </p>

    <div class="fw-bolder pt-3">Indemnity</div>
    <p>
      You agree to defend, indemnify and hold harmless IRIS Logix Solutions and
      its respective officers, directors, employees, agents and representatives
      from any and all claims (i) arising out of your breach of any of these
      terms and conditions, and any of your activities conducted in connection
      with this WebSite or Application.
    </p>

    <div class="fw-bolder pt-3">Jurisdiction</div>
    <p>
      To the fullest extent permitted by Law, you hereby expressly agree that
      any proceeding arising out of or relating to your use of the Web Site or
      Application shall be instituted in courts of Mumbai.
    </p>

    <p>
      <strong>Severability</strong>
      If any provision of these Terms of Use is found to be invalid by any court
      having competent jurisdiction, the invalidity of such provision shall not
      affect the validity of the remaining provisions of these Terms of Use,
      which shall remain in full force and effect.
    </p>

    <div class="fw-bolder pt-3">Complete Agreement</div>
    <p>
      Except as expressly provided in other written agreement between you and
      IRIS Logix Solutions these terms of use constitute the entire agreement
      between you and IRIS Logix Solutions with respect to theuse of the Web
      Site or Application.
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button  class="mx-5" color="primary" (click)="dismissModal()">Close</button>
</mat-dialog-actions>
