<h2 mat-dialog-title class="d-flex justify-content-center align-item-center">
    <div class="col pt-4"> Error List</div>
    <div class="d-flex justify-content-end col pt-4">
        <mat-icon (click)="close()">close</mat-icon>
    </div>
</h2>
<div mat-dialog-content>   
        <ul>
            <li style="color:#f44336;font-size:12px;text-transform: capitalize;" *ngFor="let item of data.message">{{item.message}}</li>
        </ul>
</div>