<div class="">
    <div mat-dialog-title>
      <div class="d-flex ">
        <span> Bulk Extend <span *ngIf="selectedCount >= 0"> - {{ selectedCount }} CEWBs selected </span> </span>
        <!-- <div class="logo">
          <img src="assets/fast-tag.png" alt="fast-tag /" style="height: 30px; width: 65px; cursor: pointer;"
            (click)="getFastagInfo()">
        </div> -->
      </div>
        <span *ngIf="ShowDisclaimer" class="disclaimer">Excludes EWB's moved to Godown</span>
  </div>
  <div  mat-dialog-content>
    <ng-container *ngIf="state === 'init' && selectedCount > 0">
      <span class="infoMsg2" *ngIf="fastagInfoLoaded"><mat-icon class="infoMsgicon">info</mat-icon> Last FasTag Location: {{fastagInfo.readerReadTime}}</span>
        <form [formGroup]="extendEWBForm" >
            <mat-form-field class="form-group">
              <mat-label>Current Place</mat-label>
              <input  type="text" matInput placeholder="Enter Current Place" formControlName="fromPlace" />      
                <div class="errorMsg" *ngIf="extendEWBForm.controls['fromPlace'].hasError('required') && (extendEWBForm.controls['fromPlace'].dirty || extendEWBForm.controls['fromPlace'].touched)">
                  Value is required
                </div>
                <div class="errorMsg" *ngIf="
                  extendEWBForm.controls['fromPlace'].hasError('pattern') &&
                  (extendEWBForm.controls['fromPlace'].dirty ||
                    extendEWBForm.controls['fromPlace'].touched)
                ">
                  Value is not correct
                </div>
             
            </mat-form-field>
          
          
            <mat-form-field class="form-group">
              <mat-label>Current State</mat-label>
              <mat-select matInput formControlName="fromState" placeholder="Select Current State">
                <mat-option value="" disabled>Select Current State</mat-option>
                <mat-option *ngFor="let state of stateList" [value]="state.key">
                  {{ state.value }}
                </mat-option>
              </mat-select>
              <div class="errorMsg" *ngIf="extendEWBForm.controls['fromState'].hasError('required') && (extendEWBForm.controls['fromState'].dirty || extendEWBForm.controls['fromState'].touched)">
                Value is required
              </div>
            </mat-form-field>
             
      
      
           <mat-form-field class="form-group">
              <mat-label>Current Pincode</mat-label>
              <input matInput type="text" placeholder="Enter Current Pincode" formControlName="fromPincode" />
              <div class="errorMsg" *ngIf="
                  extendEWBForm.controls['fromPincode'].hasError('required') &&
                  (extendEWBForm.controls['fromPincode'].dirty ||
                    extendEWBForm.controls['fromPincode'].touched)
                ">
                Value is required
              </div>
              <div class="errorMsg" *ngIf="
                  extendEWBForm.controls['fromPincode'].hasError('pattern') &&
                  (extendEWBForm.controls['fromPincode'].dirty ||
                    extendEWBForm.controls['fromPincode'].touched)
                ">
                Value is not correct
              </div>
            </mat-form-field>
      
            <!-- <mat-form-field class="form-group">
              <mat-label>Destination Pincode</mat-label>
              <input matInput type="text" formControlName="toPincode" />
            </mat-form-field> -->
            
            
            <mat-form-field class="form-group">
              <mat-label>Remaining Distance</mat-label>
              <input matInput type="text" placeholder="Enter Remaining Distance" formControlName="remainingDistance" />
              <!-- <input type="button" class="btnLogin btn_calculate" value="calculate" (click)="calculateDistance()" /> -->
              <button type="button" class="claculateBtn" mat-raised-button color="primary" (click)="calculateDistance()">
                Calculate
              </button>
              <div class="errorMsg" *ngIf="
                  extendEWBForm.controls['remainingDistance'].hasError('required') &&
                  (extendEWBForm.controls['remainingDistance'].dirty ||
                    extendEWBForm.controls['remainingDistance'].touched)
                ">
                Value is required
              </div>
              <div class="errorMsg" *ngIf="
                  extendEWBForm.controls['remainingDistance'].hasError('max') &&
                  (extendEWBForm.controls['remainingDistance'].dirty ||
                    extendEWBForm.controls['remainingDistance'].touched)
                ">
                Max Value allowed: 3000
              </div>
              <div class="errorMsg" *ngIf="
                  extendEWBForm.controls['remainingDistance'].hasError('pattern') &&
                  (extendEWBForm.controls['remainingDistance'].dirty ||
                    extendEWBForm.controls['remainingDistance'].touched)
                ">
                Value is not correct
              </div>
            </mat-form-field>
       
            <mat-form-field class="form-group mt15">
              <mat-label>Consignment is</mat-label>
              <mat-select formControlName="consignmentStatus" placeholder="Select Consignment">
                <mat-option value="" disabled>Select Consignment</mat-option>
                <mat-option value="T">IN TRANSIT</mat-option>
                <mat-option value="M">IN MOVEMENT</mat-option>
              </mat-select>
              <div class="errorMsg" *ngIf="
                  extendEWBForm.controls['consignmentStatus'].hasError('required') &&
                  (extendEWBForm.controls['consignmentStatus'].dirty ||
                    extendEWBForm.controls['consignmentStatus'].touched)
                ">
                Value is required
              </div>
            </mat-form-field>
            <mat-form-field class="form-group" *ngIf="extendEWBForm.controls['consignmentStatus'].value === 'T'">
                <mat-label>Godown</mat-label>
                <mat-select matInput (selectionChange)="onSetGodown($event.value)" placeholder="Select Current State">
                  <mat-option value="" disabled>SelectGodowns</mat-option>
                  <mat-option *ngFor="let gwd of godownList" [value]="gwd.godownId">
                    {{ gwd.godownName }}
                  </mat-option>
                </mat-select>
               
              </mat-form-field>
            <mat-form-field class="form-group mt15" *ngIf="extendEWBForm.controls['consignmentStatus'].value === 'T'">
                <mat-label>Transit Type</mat-label>
                <mat-select formControlName="transitType" placeholder="Select Transit Type">
                  <mat-option value="" disabled>Select Transit Type</mat-option>
                  <mat-option value="R">Road</mat-option>
                  <mat-option value="W">Warehouse</mat-option>
                  <mat-option value="O">Others</mat-option>
                </mat-select>
                <div class="errorMsg" *ngIf="
                    extendEWBForm.controls['transitType'].hasError('required') &&
                    (extendEWBForm.controls['transitType'].dirty ||
                      extendEWBForm.controls['transitType'].touched)
                  ">
                  Value is required
                </div>
              </mat-form-field>
              <mat-form-field class="form-group" *ngIf="extendEWBForm.controls['consignmentStatus'].value === 'T'">
                <mat-label>Address Line 1</mat-label>
                <input matInput type="text" formControlName="addressLine1" />
                <div class="errorMsg" *ngIf="
                extendEWBForm.controls['addressLine1'].hasError('required') &&
                (extendEWBForm.controls['addressLine1'].dirty ||
                  extendEWBForm.controls['addressLine1'].touched)
              ">
              Value is required
            </div>
              </mat-form-field>
              <mat-form-field class="form-group" *ngIf="extendEWBForm.controls['consignmentStatus'].value === 'T'">
                <mat-label>Address Line 2</mat-label>
                <input matInput type="text" formControlName="addressLine2" />
                <div class="errorMsg" *ngIf="
                extendEWBForm.controls['addressLine2'].hasError('required') &&
                (extendEWBForm.controls['addressLine2'].dirty ||
                  extendEWBForm.controls['addressLine2'].touched)
              ">
              Value is required
            </div>
              </mat-form-field>
              <mat-form-field class="form-group" *ngIf="extendEWBForm.controls['consignmentStatus'].value === 'T'">
                <mat-label>Place</mat-label>
                <input matInput type="text" formControlName="addressLine3" />
                <div class="errorMsg" *ngIf="
                extendEWBForm.controls['addressLine3'].hasError('required') &&
                (extendEWBForm.controls['addressLine3'].dirty ||
                  extendEWBForm.controls['addressLine3'].touched)
              ">
              Value is required
            </div>
              </mat-form-field>
            <mat-form-field class="form-group mt15" *ngIf="extendEWBForm.controls['consignmentStatus'].value === 'M'">
              <mat-label>Transportation Mode</mat-label>
              <mat-select formControlName="transMode" placeholder="Select Transportation Mode">
                <mat-option value="" disabled>Select Transportation Mode</mat-option>
                <mat-option value="1">Road</mat-option>
                <mat-option value="2">Rail</mat-option>
                <mat-option value="3">Air</mat-option>
                <mat-option value="4">Ship/Road Cum Ship</mat-option>
              </mat-select>
              <div class="errorMsg" *ngIf="
                  transMode &&
                  extendEWBForm.controls['transMode'].hasError('required') &&
                  (extendEWBForm.controls['transMode'].dirty ||
                    extendEWBForm.controls['transMode'].touched)
                ">
                Value is required
              </div>
            </mat-form-field>
      
            <mat-form-field class="form-group"  *ngIf="extendEWBForm.controls['transMode'].value === '1' && extendEWBForm.controls['consignmentStatus'].value === 'M'">
              <mat-label>Vehicle No.</mat-label>
              <input matInput type="text" placeholder="Enter Vehicle No." formControlName="vehicleNo" />
              <div class="errorMsg" *ngIf="
                  extendEWBForm.controls['vehicleNo'].hasError('required') &&
                  (extendEWBForm.controls['vehicleNo'].dirty ||
                    extendEWBForm.controls['vehicleNo'].touched)
                ">
                Value is required
              </div>
            </mat-form-field>
      
            <mat-form-field class="form-group" *ngIf="transDocNo && extendEWBForm.controls['transMode'].value === '1'" >
              <mat-label>Transporter Document No.</mat-label>
              <input matInput type="text" placeholder="Enter Transporter Document No." formControlName="transDocNo" />
              <div class="errorMsg" *ngIf="
                  transDocNo &&
                  extendEWBForm.controls['transDocNo'].hasError('required') &&
                  (extendEWBForm.controls['transDocNo'].dirty ||
                    extendEWBForm.controls['transDocNo'].touched)
                ">
                Value is required
              </div>
            </mat-form-field>
            <mat-form-field class="form-group" *ngIf="transDocNo && extendEWBForm.controls['transMode'].value === '2'" >               
                <mat-label *ngIf="extendEWBForm.controls['transMode'].value === '2'">RR No.</mat-label>
                <input matInput type="text" placeholder="Enter RR No." formControlName="transDocNo" />
                <div class="errorMsg" *ngIf="
                    transDocNo &&
                    extendEWBForm.controls['transDocNo'].hasError('required') &&
                    (extendEWBForm.controls['transDocNo'].dirty ||
                      extendEWBForm.controls['transDocNo'].touched)
                  ">
                  Value is required
                </div>
              </mat-form-field>
              <mat-form-field class="form-group" *ngIf="transDocNo && extendEWBForm.controls['transMode'].value === '3'" >
                <mat-label >Airway Bill No</mat-label>               
                <input matInput type="text" placeholder="Enter Airway Bill No." formControlName="transDocNo" />
                <div class="errorMsg" *ngIf="
                    transDocNo &&
                    extendEWBForm.controls['transDocNo'].hasError('required') &&
                    (extendEWBForm.controls['transDocNo'].dirty ||
                      extendEWBForm.controls['transDocNo'].touched)
                  ">
                  Value is required
                </div>
              </mat-form-field>
              <mat-form-field class="form-group" *ngIf="transDocNo && extendEWBForm.controls['transMode'].value === '4'" >
                <mat-label>Bill of lading No</mat-label>
                <input matInput type="text" placeholder="Enter Bill of lading No." formControlName="transDocNo" />
                <div class="errorMsg" *ngIf="
                    transDocNo &&
                    extendEWBForm.controls['transDocNo'].hasError('required') &&
                    (extendEWBForm.controls['transDocNo'].dirty ||
                      extendEWBForm.controls['transDocNo'].touched)
                  ">
                  Value is required
                </div>
              </mat-form-field>
              <mat-form-field *ngIf="transDocNo && extendEWBForm.controls['transMode'].value === '1'">
                <mat-label>Transporter Document Date</mat-label>
                <input formControlName="transDocDate" matInput [max]="currentDate" [matDatepicker]="myDatepicker" placeholder="Select Transporter Document Date"
                  readonly />
                <mat-datepicker-toggle [for]="myDatepicker" class="inputDateIcon"></mat-datepicker-toggle>
                <mat-datepicker disabled="false" touchUi #myDatepicker></mat-datepicker>
                <div class="errorMsg" *ngIf="
                transDocDate &&
                extendEWBForm.controls['transDocDate'].hasError('required') &&
                (extendEWBForm.controls['transDocDate'].dirty ||
                  extendEWBForm.controls['transDocDate'].touched)
              ">
              Value is required
            </div>
              </mat-form-field>
            <mat-form-field *ngIf="transDocNo && extendEWBForm.controls['transMode'].value === '2'">
              <mat-label>RR Date</mat-label>
              <input formControlName="transDocDate" matInput [max]="currentDate" [matDatepicker]="myDatepicker" placeholder="Select RR Date"
                readonly />
              <mat-datepicker-toggle [for]="myDatepicker" class="inputDateIcon"></mat-datepicker-toggle>
              <mat-datepicker disabled="false" touchUi #myDatepicker></mat-datepicker>
              <div class="errorMsg" *ngIf="
              transDocDate &&
              extendEWBForm.controls['transDocDate'].hasError('required') &&
              (extendEWBForm.controls['transDocDate'].dirty ||
                extendEWBForm.controls['transDocDate'].touched)
            ">
            Value is required
          </div>
            </mat-form-field>
            <mat-form-field *ngIf="transDocNo && extendEWBForm.controls['transMode'].value === '3'">
                <mat-label >Airway Bill Date</mat-label>
                <input formControlName="transDocDate" matInput [max]="currentDate" [matDatepicker]="myDatepicker" placeholder="Select Airway Bill Date"
                  readonly />
                <mat-datepicker-toggle [for]="myDatepicker" class="inputDateIcon"></mat-datepicker-toggle>
                <mat-datepicker disabled="false" touchUi #myDatepicker></mat-datepicker>
                <div class="errorMsg" *ngIf="
                transDocDate &&
                extendEWBForm.controls['transDocDate'].hasError('required') &&
                (extendEWBForm.controls['transDocDate'].dirty ||
                  extendEWBForm.controls['transDocDate'].touched)
              ">
              Value is required
            </div>
              </mat-form-field>
              <mat-form-field *ngIf="transDocNo  && extendEWBForm.controls['transMode'].value === '4'">
                <mat-label>Bill of lading Date</mat-label>
                <input formControlName="transDocDate" matInput [max]="currentDate" [matDatepicker]="myDatepicker" placeholder="Select Bill of lading Date"
                  readonly />
                <mat-datepicker-toggle [for]="myDatepicker" class="inputDateIcon"></mat-datepicker-toggle>
                <mat-datepicker disabled="false" touchUi #myDatepicker></mat-datepicker>
                <div class="errorMsg" *ngIf="
                transDocDate &&
                extendEWBForm.controls['transDocDate'].hasError('required') &&
                (extendEWBForm.controls['transDocDate'].dirty ||
                  extendEWBForm.controls['transDocDate'].touched)
              ">
              Value is required
            </div>
              </mat-form-field>
              
      <!--
            <mat-form-field class="form-group mt5">
              <mat-label>Transporter Document Date</mat-label>
              <input formControlName="transDocDate" [max]="currentDate" [matDatepicker]="myDatepicker" class="inputDate"
                placeholder="Select Date" />
              <mat-datepicker-toggle [for]="myDatepicker" class="inputDateIcon"></mat-datepicker-toggle>
              <mat-datepicker disabled="false" touchUi #myDatepicker></mat-datepicker>
              <div class="errorMsg" *ngIf="
                  transDocDate &&
                  extendEWBForm.controls['transDocDate'].hasError('required') &&
                  (extendEWBForm.controls['transDocDate'].dirty ||
                    extendEWBForm.controls['transDocDate'].touched)
                ">
                Value is required
              </div>
            </mat-form-field>
      -->
            <mat-form-field class="form-group mt15">
              <mat-label>Extension Reason code</mat-label>
              <mat-select formControlName="extnRsnCode" placeholder="Select Extension Reason">
                <mat-option value="" disabled>Select Extension Reason</mat-option>
                <mat-option value="1">NATURAL CALAMITY</mat-option>
                <mat-option value="2">LAW AND ORDER SITUATION</mat-option>
                <mat-option value="4">TRANSSHIPMENT</mat-option>
                <mat-option value="5">ACCIDENT</mat-option>
                <mat-option value="99">Other</mat-option>
              </mat-select>
              <div class="errorMsg" *ngIf="
                  extendEWBForm.controls['extnRsnCode'].hasError('required') &&
                  (extendEWBForm.controls['extnRsnCode'].dirty ||
                    extendEWBForm.controls['extnRsnCode'].touched)
                ">
                Value is required
              </div>
            </mat-form-field>
      
            <mat-form-field class="form-group">
              <mat-label>Extension Remarks</mat-label>
              <input matInput type="text" placeholder="Enter Extension Remarks" formControlName="extnRemarks" />
              <div class="errorMsg" *ngIf="
                  extendEWBForm.controls['extnRemarks'].hasError('required') &&
                  (extendEWBForm.controls['extnRemarks'].dirty ||
                    extendEWBForm.controls['extnRemarks'].touched)
                ">
                Value is required
              </div>
            </mat-form-field> 
      
            <div class="form-group landingBtn">
              <div>
                <button type="submit" mat-raised-button color="primary" (click)="extendEWB()">
                  Submit
                </button>
              </div>
            </div>
          </form>
      <!-- <div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="extendEWB()">Submit</button>
      </div> -->
    </ng-container>
   
  
    <ng-container  *ngIf="state === 'progress' && selectedCount > 0">
      <mat-progress-bar mode="determinate" value="selectedCount">Extension in progress</mat-progress-bar>
      <div>Kindly do not press the back button or change screens....</div>
    </ng-container>
  
    <div class="results" *ngIf="state === 'result' && selectedCount > 0">
       <!--<mat-toolbar color="primary">
        <mat-toolbar-row>
          <mat-segmented-button [(ngModel)]="viewSegment">
            <mat-button-toggle value="success">Success</mat-button-toggle>
            <mat-button-toggle value="failure">Failure</mat-button-toggle>
          </mat-segmented-button>
        </mat-toolbar-row>
      </mat-toolbar> -->
      <div class="row">
        <div class="col-12">
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-secondary" [class.active]="viewSegment === 'success'"
              (click)="segmentChanged('success')">Success</button>
            <button type="button" class="btn btn-secondary" [class.active]="viewSegment === 'failure'"
              (click)="segmentChanged('failure')">Failure</button>
          </div>
        </div>
      </div>
      <div *ngIf="viewSegment === 'success'">
        <table>
          <tr>
            <th>Total EWBs</th>
            <th>Status</th>
          </tr>
          <tr>
            <td>{{ successEWBs.length }}</td>
            <td>Extended</td>
          </tr>
        </table>
      </div>
      <table *ngIf="viewSegment === 'failure'">
        <tr>
          <th>EWB No.</th>
          <th>Message</th>
          <th>Error</th>
        </tr>
        <tr *ngFor="let item of failedEWBs">
          <td>{{ item.ewbNo }}</td>
          <td>{{ item.response.message }}</td>
          <td>{{ item.response?.errorList[0]?.message }}</td>
        </tr>
      </table>
    </div>
    <ng-container *ngIf="selectedCount === 0">
      Only ewaybills not in godown can be extended. Godown EWBs will be extended as per schedule
    </ng-container>
  </div>
  </div>