import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { BaseResponse } from 'src/app/_models/Response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BusinessManagementService {
  activeGSTIN: any = null;
  activeBusiness = new BehaviorSubject(this.activeGSTIN);
  godownData: any;
  constructor(private http: HttpClient) { }

  getActiveBusiness(): Observable<any> {
    return this.activeBusiness.asObservable();
  }

  setActiveBusiness(data: any): boolean {
    this.activeBusiness.next(data);
    return true;
  }

  fetchGodownList() {
    return this.http.get(`${environment.apiUrl}/godown/all`).pipe(
      map((response: any) => {
  
          return response.response;
      })
    );
  }

  fetchGstinList() {
    return this.http.get<BaseResponse>(`${environment.apiUrl}/org/getbusiness`).pipe(
      map((response: BaseResponse) => {
        return response.response;
      })
    );
  }  
  getFastagInfo(ewbNo: any, gstin: any) {
    return this.http
      .get(
        `${environment.apiUrl}/logilocker/fastag/location?ewbNo=${ewbNo}&gstin=${gstin}`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addGodown(data: any) {
    return this.http.post(`${environment.apiUrl}/godown/add`, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateTransporterMultiple(data: any, gstin: any) {
    return this.http
      .put(`${environment.apiUrl}/ewb/updateTransporter?gstin=${gstin}`, data)
      .pipe(
        map((response: any) => {
          return { response, ewbNo: data.ewbNo };
        })
      );
  }
  updateTransporter(data: any, gstin: any) {
    return this.http
      .put(`${environment.apiUrl}/ewb/updateTransporter?gstin=${gstin}`, data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  consolidatedEWB(data: any, gstin: any) {
    return this.http
      .post(`${environment.apiUrl}/cewb/generate?gstin=${gstin}`, data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editGodown(data: any) {
    return this.http.post(`${environment.apiUrl}/godown/edit`, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  addGstin(data: any) {
    return this.http.post(`${environment.apiUrl}/org/addbusiness`, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  editGSTIN(data: any, gstin: any) {
    return this.http
      .post(`${environment.apiUrl}/org/modifybusiness?gstin=${gstin}`, data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  inviteUser(data: any) {
    return this.http.post(`${environment.apiUrl}/org/adduser`, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  resendInviteUser(user: any) {
    return this.http
      .get(`${environment.apiUrl}/org/resendinvite?userid=${user.userid}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  activeInactiveUser(user: any) {
    return this.http
      .put(
        `${environment.apiUrl}/org/updateActivation?userId=${user.userid}&active=${user.active}`, user
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateUser(data: any) {
    return this.http.post(`${environment.apiUrl}/org/updateuser`, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  setGodownData(data: any) {

    this.godownData = data;
  }
  getGodownData() {
    return this.godownData;
  }

  fetchAssignedGodowns(gstn: any) {
    return this.http
      .get(`${environment.apiUrl}/godown/assignedGodowns?gstin=${gstn}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchBusinessList() {
    return this.http.get(`${environment.apiUrl}/auth/organisations`).pipe(
      map((response: any) => {
        if (response.status === 1) {
          return response.response;
        }
      })
    );
  }
  // here here here
  getMyBusiness(): Observable<any> {
    return this.http.get<BaseResponse>(`${environment.apiUrl}/org/getbusiness`).pipe(
      map((response: BaseResponse) => {
        if (response.status === 1) {
          return response.response;
        }
      })
    );
  }


  fetchUserList() {
    return this.http.get(`${environment.apiUrl}/org/getusers`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getStateData(pincode: number) {
    return this.http
      .get(`${environment.apiUrl}/search/gstStateCode?pincode=${pincode}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteUser(userid: any) {
    return this.http
      .delete(`${environment.apiUrl}/org/deleteuser?userid=${userid}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  generateEWB(data: any, gstin: any) {
    return this.http
      .post(`${environment.apiUrl}/ewb/generate?gstin=${gstin}`, data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  removeGodown(data: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        godownId: data,
      },
    };

    return this.http
      .delete(`${environment.apiUrl}/godown/delete`, options)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addGroup(data: any, gstin: any) {
    return this.http
      .put(
        `${environment.apiUrl}/ewb/multiVehicle/initiate?gstin=${gstin}`,
        data
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  
  partBMultiVehicle(data: any, gstin: any) {
    return this.http
      .put(`${environment.apiUrl}/ewb/multiVehicle/add?gstin=${gstin}`, data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editPartBMultiVehicle(data: any, gstin: any) {
    return this.http
      .put(`${environment.apiUrl}/ewb/multiVehicle/update?gstin=${gstin}`, data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAllSubscriptions() {
    return this.http.get(`${environment.apiUrl}/org/subscriptions`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getSubscriptionInfo(orderID: any) {
    return this.http
      .get(`${environment.apiUrl}/org/subscription?id=${orderID}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  downloadEWBdata(body: any, gstin: any) {
    return this.http
      .post(`${environment.apiUrl}/ewb/download?gstin=${gstin}`, body, {
        responseType: 'text',
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  stateListData() {
    return [
      { key: '37', value: 'Andhra Pradesh', stateCode: 'AP' },
      { key: '38', value: 'Ladakh', stateCode: 'LA' },
      { key: '12', value: 'Arunachal Pradesh', stateCode: 'AR' },
      { key: '18', value: 'Assam', stateCode: 'AS' },
      { key: '10', value: 'Bihar', stateCode: 'BR' },
      { key: '22', value: 'Chhattisgarh', stateCode: 'CG' },
      { key: '30', value: 'Goa', stateCode: 'GA' },
      { key: '24', value: 'Gujarat', stateCode: 'GJ' },
      { key: '06', value: 'Haryana', stateCode: 'HR' },
      { key: '02', value: 'Himachal Pradesh', stateCode: 'HP' },
      { key: '01', value: 'Jammu and Kashmir', stateCode: 'JK' },
      { key: '20', value: 'Jharkhand', stateCode: 'JH' },
      { key: '29', value: 'Karnataka', stateCode: 'KA' },
      { key: '32', value: 'Kerala', stateCode: 'KL' },
      { key: '23', value: 'Madhya Pradesh', stateCode: 'MP' },
      { key: '27', value: 'Maharashtra', stateCode: 'MH' },
      { key: '14', value: 'Manipur', stateCode: 'MN' },
      { key: '17', value: 'Meghalaya', stateCode: 'ML' },
      { key: '15', value: 'Mizoram', stateCode: 'MZ' },
      { key: '13', value: 'Nagaland', stateCode: 'NL' },
      { key: '21', value: 'Orissa', stateCode: 'OR' },
      { key: '03', value: 'Punjab', stateCode: 'PN' },
      { key: '08', value: 'Rajasthan', stateCode: 'RJ' },
      { key: '11', value: 'Sikkim', stateCode: 'SK' },
      { key: '33', value: 'Tamil Nadu', stateCode: 'TN' },
      { key: '36', value: 'Telangana', stateCode: 'TS' },
      { key: '16', value: 'Tripura', stateCode: 'TR' },
      { key: '05', value: 'Uttarakhand', stateCode: 'UK' },
      { key: '09', value: 'Uttar Pradesh', stateCode: 'UP' },
      { key: '19', value: 'West Bengal', stateCode: 'WB' },
      { key: '35', value: 'Andaman and Nicobar Islands', stateCode: 'AN' },
      { key: '04', value: 'Chandigarh', stateCode: 'CH' },
      { key: '26', value: 'Dadra and Nagar Haveli', stateCode: 'DN' },
      { key: '25', value: 'Daman and Diu', stateCode: 'DD' },
      { key: '07', value: 'Delhi', stateCode: 'DL' },
      { key: '31', value: 'Lakshadweep', stateCode: 'LD' },
      { key: '34', value: 'Pondicherry', stateCode: 'PY' },
      { key: '97', value: 'Other Territory', stateCode: 'O/T' },
      { key: '99', value: 'Other Country', stateCode: 'O/C' },
    ].sort((a, b) => a.value.localeCompare(b.value));
  }

  stateListDataCons() {
    return [
      { key: '37', value: 'Andhra Pradesh', stateCode: 'AP' },
      { key: '38', value: 'Ladakh', stateCode: 'LA' },
      { key: '12', value: 'Arunachal Pradesh', stateCode: 'AR' },
      { key: '18', value: 'Assam', stateCode: 'AS' },
      { key: '10', value: 'Bihar', stateCode: 'BR' },
      { key: '22', value: 'Chhattisgarh', stateCode: 'CG' },
      { key: '30', value: 'Goa', stateCode: 'GA' },
      { key: '24', value: 'Gujarat', stateCode: 'GJ' },
      { key: '06', value: 'Haryana', stateCode: 'HR' },
      { key: '6', value: 'Haryana', stateCode: 'HR' },
      { key: '02', value: 'Himachal Pradesh', stateCode: 'HP' },
      { key: '2', value: 'Himachal Pradesh', stateCode: 'HP' },
      { key: '01', value: 'Jammu and Kashmir', stateCode: 'JK' },
      { key: '1', value: 'Jammu and Kashmir', stateCode: 'JK' },
      { key: '20', value: 'Jharkhand', stateCode: 'JH' },
      { key: '29', value: 'Karnataka', stateCode: 'KA' },
      { key: '32', value: 'Kerala', stateCode: 'KL' },
      { key: '23', value: 'Madhya Pradesh', stateCode: 'MP' },
      { key: '27', value: 'Maharashtra', stateCode: 'MH' },
      { key: '14', value: 'Manipur', stateCode: 'MN' },
      { key: '17', value: 'Meghalaya', stateCode: 'ML' },
      { key: '15', value: 'Mizoram', stateCode: 'MZ' },
      { key: '13', value: 'Nagaland', stateCode: 'NL' },
      { key: '21', value: 'Orissa', stateCode: 'OR' },
      { key: '03', value: 'Punjab', stateCode: 'PN' },
      { key: '3', value: 'Punjab', stateCode: 'PN' },
      { key: '08', value: 'Rajasthan', stateCode: 'RJ' },
      { key: '8', value: 'Rajasthan', stateCode: 'RJ' },
      { key: '11', value: 'Sikkim', stateCode: 'SK' },
      { key: '33', value: 'Tamil Nadu', stateCode: 'TN' },
      { key: '36', value: 'Telangana', stateCode: 'TS' },
      { key: '16', value: 'Tripura', stateCode: 'TR' },
      { key: '05', value: 'Uttarakhand', stateCode: 'UK' },
      { key: '5', value: 'Uttarakhand', stateCode: 'UK' },
      { key: '09', value: 'Uttar Pradesh', stateCode: 'UP' },
      { key: '9', value: 'Uttar Pradesh', stateCode: 'UP' },
      { key: '19', value: 'West Bengal', stateCode: 'WB' },
      { key: '35', value: 'Andaman and Nicobar Islands', stateCode: 'AN' },
      { key: '04', value: 'Chandigarh', stateCode: 'CH' },
      { key: '4', value: 'Chandigarh', stateCode: 'CH' },
      { key: '26', value: 'Dadra and Nagar Haveli', stateCode: 'DN' },
      { key: '25', value: 'Daman and Diu', stateCode: 'DD' },
      { key: '07', value: 'Delhi', stateCode: 'DL' },
      { key: '7', value: 'Delhi', stateCode: 'DL' },
      { key: '31', value: 'Lakshadweep', stateCode: 'LD' },
      { key: '34', value: 'Pondicherry', stateCode: 'PY' },
      { key: '97', value: 'Other Territory', stateCode: 'O/T' },
      { key: '99', value: 'Other Country', stateCode: 'O/C' },
    ].sort((a, b) => a.value.localeCompare(b.value));
  }
  transportModeListData() {
    return [
      { key: 1, value: 'Road' },
      { key: 2, value: 'Rail' },
      { key: 3, value: 'Air' },
      { key: 4, value: 'Ship/Road Cum Ship' },
      // { key: 5, value: 'Transit' }
    ];
  }

  transportModeListWithTransist(): { key: number; value: string }[] {
    return [
      { key: 1, value: 'Road' },
      { key: 2, value: 'Rail' },
      { key: 3, value: 'Air' },
      { key: 4, value: 'Ship/Road Cum Ship' },
      { key: 5, value: 'Transit' },
    ];
  }

  transactionTypeList() {
    return [
      { key: 1, value: 'Regular' },
      { key: 2, value: 'Bill To - Ship To' },
      { key: 3, value: 'Bill From - Dispatch From' },
      { key: 4, value: 'Bill To - Ship To and Bill From - Dispatch From' },
    ];
  }

  supplyTypeData() {
    return [
      { key: 'I', value: 'Inward' },
      { key: 'O', value: 'Outward' },
    ];
  }

  subSupplyTypeData() {
    return [
      { key: 1, value: 'Supply' },
      { key: 2, value: 'Import' },
      { key: 3, value: 'Export' },
      { key: 4, value: 'Job Work' },
      { key: 5, value: 'For Own Use' },
      { key: 6, value: 'Job work Returns' },
      { key: 7, value: 'Sales Return' },
      { key: 8, value: 'Others' },
      { key: 9, value: 'SKD/CKD/Lots' },
      { key: 10, value: 'Line Sales' },
      { key: 11, value: 'Recipient  Not Known' },
      { key: 12, value: 'Exhibition or Fairs' },
    ];
  }

  documentTypeData() {
    return [
      { value: 'Tax Invoice', key: 'INV' },
      { value: 'Bill of Supply', key: 'BIL' },
      { value: 'Bill of Entry', key: 'BOE' },
      { value: 'Challan', key: 'CHL' },
      { value: 'Others', key: 'OTH' },
    ];
  }

  unitData() {
    return [
      { key: 'BAG', value: 'BAGS' },
      { key: 'BAL', value: 'BALE' },
      { key: 'BDL', value: 'BUNDLES' },
      { key: 'BKL', value: 'BUCKLES' },
      { key: 'BOU', value: 'BILLION OF UNITS' },
      { key: 'BOX', value: 'BOX' },
      { key: 'BTL', value: 'BOTTLES' },
      { key: 'BUN', value: 'BUNCHES' },
      { key: 'CAN', value: 'CANS' },
      { key: 'CBM', value: 'CUBIC METERS' },
      { key: 'CCM', value: 'CUBIC CENTIMETERS' },
      { key: 'CMS', value: 'CENTI METERS' },
      { key: 'CTN', value: 'CARTONS' },
      { key: 'DOZ', value: 'DOZENS' },
      { key: 'DRM', value: 'DRUMS' },
      { key: 'GGK', value: 'GREAT GROSS' },
      { key: 'GMS', value: 'GRAMMES' },
      { key: 'GRS', value: 'GROSS' },
      { key: 'GYD', value: 'GROSS YARDS' },
      { key: 'KGS', value: 'KILOGRAMS' },
      { key: 'KLR', value: 'KILOLITRE' },
      { key: 'KME', value: 'KILOMETRE' },
      { key: 'MLT', value: 'MILILITRE' },
      { key: 'MTR', value: 'METERS' },
      { key: 'MTS', value: 'METRIC TON' },
      { key: 'NOS', value: 'NUMBERS' },
      { key: 'OTH', value: 'OTHERS' },
      { key: 'PAC', value: 'PACKS' },
      { key: 'PCS', value: 'PIECES' },
      { key: 'PRS', value: 'PAIRS' },
      { key: 'QTL', value: 'QUINTAL' },
      { key: 'ROL', value: 'ROLLS' },
      { key: 'SET', value: 'SETS' },
      { key: 'SQF', value: 'SQUARE FEET' },
      { key: 'SQM', value: 'SQUARE METERS' },
      { key: 'SQY', value: 'SQUARE YARDS' },
      { key: 'TBS', value: 'TABLETS' },
      { key: 'TGM', value: 'TEN GROSS' },
      { key: 'THD', value: 'THOUSANDS' },
      { key: 'TON', value: 'TONNES' },
      { key: 'TUB', value: 'TUBES' },
      { key: 'UGS', value: 'US GALLONS' },
      { key: 'UNT', value: 'UNITS' },
      { key: 'YDS', value: 'YARDS' },
      { key: 'LTR', value: 'LITRES' },
    ];
  }

  igstData() {
    return [
      { key: '0.00', value: '0.00' },
      { key: '0.10', value: '0.10' },
      { key: '0.25', value: '0.25' },
      { key: '0.50', value: '0.50' },
      { key: '3.00', value: '3.00' },
      { key: '5.00', value: '5.00' },
      { key: '12.00', value: '12.00' },
      { key: '18.00', value: '18.00' },
      { key: '28.00', value: '28.00' },
    ];
  }

  cgstData() {
    return [
      { key: '0.00', value: '0.00' },
      { key: '0.05', value: '0.05' },
      { key: '0.125', value: '0.125' },
      { key: '0.25', value: '0.25' },
      { key: '1.50', value: '1.50' },
      { key: '2.50', value: '2.50' },
      { key: '6.00', value: '6.00' },
      { key: '9.00', value: '9.00' },
      { key: '14.00', value: '14.00' },
    ];
  }
  sgstData() {
    return [
      { key: '0.00', value: '0.00' },
      { key: '0.05', value: '0.05' },
      { key: '0.125', value: '0.125' },
      { key: '0.25', value: '0.25' },
      { key: '1.50', value: '1.50' },
      { key: '2.50', value: '2.50' },
      { key: '6.00', value: '6.00' },
      { key: '9.00', value: '9.00' },
      { key: '14.00', value: '14.00' },
    ];
  }

  cessAdvolData() {
    return [
      { id: '0', name: '0' },
      { id: '1', name: '1' },
      { id: '3', name: '3' },
      { id: '5', name: '5' },
      { id: '11', name: '11' },
      { id: '12', name: '12' },
      { id: '12.5', name: '12.5' },
      { id: '15', name: '15' },
      { id: '17', name: '17' },
      { id: '20', name: '20' },
      { id: '21', name: '21' },
      { id: '22', name: '22' },
      { id: '36', name: '36' },
      { id: '49', name: '49' },
      { id: '60', name: '60' },
      { id: '61', name: '61' },
      { id: '65', name: '65' },
      { id: '71', name: '71' },
      { id: '72', name: '72' },
      { id: '89', name: '89' },
      { id: '96', name: '96' },
      { id: '142', name: '142' },
      { id: '160', name: '160' },
      { id: '204', name: '204' },
      { id: '290', name: '290' },
    ];
  }

  cessNonAdvolData() {
    return [
      { id: 0, name: 0 },
      { id: 400, name: 400 },
      { id: 2076, name: 2076 },
      { id: 2747, name: 2747 },
      { id: 3668, name: 3668 },
      { id: 4006, name: 4006 },
      { id: 4170, name: 4170 },
    ];
  }

  taxApplicableTypes() {
    return [
      { key: 'T', value: 'Taxable' },
      { key: 'L', value: 'Nil' },
      { key: 'E', value: 'Exempt' },
      { key: 'N', value: 'Non GST' },
    ];
  }
  vehicleTypeList() {
    return [
      { key: 'R', value: 'Regular' },
      { key: 'O', value: 'Over Dimensional Cargo' },
    ];
  }

  reasonListData() {
    return [
      { key: 1, value: 'Due to Break Down' },
      { key: 2, value: 'Due to Transhipment' },
      { key: 3, value: 'Others (Pls. Specify)' },
      { key: 4, value: 'First' },
    ];
  }
}
