import { Injectable } from '@angular/core';
import { ViewEwayDashboardService } from '../view-eway-dashboard.service';
import { BusinessManagementService } from '../business-management.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewEwayDashboardResolverService {
  constructor(
    private backend: ViewEwayDashboardService,
    private activeBusiness: BusinessManagementService,
  ) { }

  activeGstin = false;
  activeBus: any;
  isOrganisation :any;

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.activeBusiness.getActiveBusiness().subscribe((active) => {
      if(active){
      this.isOrganisation = active.isOrganisation;
      if(active && !active.isOrganisation){
        if (active === null) { this.activeGstin = false; } else { this.activeGstin = true; this.activeBus = active; }
      }
    }
    });
    if (this.activeGstin && !this.isOrganisation) {
    }
}
}