<nav class="navbar navbar-expand-lg navbar-light fixed-bottom">
  <div class="container d-flex justify-content-center topBorder">
    <div class="d-flex deta">
      <a
        (click)="setActiveTab(tabs.DASHBOARD, routes.DASHBOARD)"
        [class.active]="activeTab === tabs.DASHBOARD"
        class="d-flex flex-column justify-content-center align-items-center px-4 text-decoration-none text-secondary cursor-pointer"
      >
        <mat-icon>home</mat-icon>
        <span class="font14">{{ tabs.DASHBOARD }}</span>
      </a>
      <a
        (click)="setActiveTab(tabs.GODOWN, routes.GODOWN)"
        [class.active]="activeTab === tabs.GODOWN"
        class="d-flex flex-column justify-content-center align-items-center px-4 text-decoration-none text-secondary cursor-pointer"
      >
        <mat-icon>business</mat-icon>
        <span class="font14">{{ tabs.GODOWN }}</span>
      </a>
      <a
        (click)="setActiveTab(tabs.WATCHLIST, routes.WATCHLIST)"
        [class.active]="activeTab === tabs.WATCHLIST"
        class="d-flex flex-column justify-content-center align-items-center px-4 text-decoration-none text-secondary cursor-pointer"
      >
        <mat-icon>bookmark</mat-icon>
        <span class="font14">{{ tabs.WATCHLIST }}</span>
      </a>
      <a
        (click)="setActiveTab(tabs.UTILITIES, routes.UTILITIES)"
        [class.active]="activeTab === tabs.UTILITIES"
        class="d-flex flex-column justify-content-center align-items-center px-4 text-decoration-none text-secondary cursor-pointer"
      >
        <mat-icon>settings</mat-icon>
        <span class="font14">{{ tabs.UTILITIES }}</span>
      </a>
    </div>
  </div>
</nav>
