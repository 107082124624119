import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-gstin-header-dialog',
  templateUrl: './gstin-header-dialog.component.html',
  styleUrls: ['./gstin-header-dialog.component.scss'],
})
export class GstinHeaderDialogComponent {
  selectedOrganisation: any;
  selectedGSTIN: any;

  constructor(
    public dialogRef: MatDialogRef<GstinHeaderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.selectedOrganisation = this.data.selectedOrganisation;
    if (this.data.orgs) {
      this.selectedGSTIN = this.panSearch(this.data.orgs, this.data.gstins)
    } else if (this.data.selectedGSTIN) {
      this.selectedGSTIN = this.search(this.data.selectedGSTIN, this.data.gstins)
    }
    if (this.data.temp) {
      //this.data.temp = JSON.parse(this.data.temp).map((g: any) => g.gstn).toString()
      this.getActiveGstin()
    }
  }
  panSearch(selectGstin: any, gstins: any) {
    for (const arr of gstins) {
      if (arr.isOrganisation === selectGstin) {
        return arr;
      }
    }
  }
  search(selectGstin: any, gstins: any) {
    for (const arr of gstins) {
      if (arr.gstn === selectGstin) {
        return arr;
      }
    }
  }
  onOk() {
    this.dialogRef.close(this.selectedGSTIN);
  }
  onCancel() {
    this.dialogRef.close(null);
  }
  selectGstin(event: any) {
    this.selectedGSTIN = event;
  }
  getActiveGstin() {
    if (this.data.temp == 'null') {
      this.selectedGSTIN = this.panSearch(true, this.data.gstins)
    }
    else {
      this.selectedGSTIN = this.search(this.data.temp, this.data.gstins)
    }
  }
}
