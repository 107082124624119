import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { GstinHeaderModule } from '../shared/components/gstin-header/gstin-header.module';
import { FooterBarModule } from '../shared/components/footer-bar/footer-bar.module';


@NgModule({
  declarations: [
    HomeComponent,
  ],
  exports: [HomeComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    GstinHeaderModule,
    FooterBarModule,
    RouterModule.forChild([{ path: '', component: HomeComponent }])
  ]
})
export class HomeModule { }
