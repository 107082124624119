import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-name',
  templateUrl: './video-name.component.html',
  styleUrls: ['./video-name.component.scss']
})
export class VideoNameComponent {
  constructor(
    public dialogRef: MatDialogRef<VideoNameComponent>
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
