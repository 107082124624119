import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessManagementService } from '../../services/business-management.service';
import { ToasterService } from '../../services/toaster.service';

export enum TABS {
  DASHBOARD = 'Dashboard',
  GODOWN = 'My Godown',
  WATCHLIST = 'My Watchlist',
  UTILITIES = 'Utilities',
}

export enum ROUTES {
  DASHBOARD = '/dashboard/home',
  GODOWN = '/widgets/godowns',
  WATCHLIST = '/widgets/watch-list',
  UTILITIES = '/qrscan',
}

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit {
  isOrganisation: any;
  
  ngOnInit(): void {
    this.BMS.activeBusiness.subscribe((res) => {
      if (res) {
        this.isOrganisation = res.gstn;
      }
    })
    switch (this.router.url) {
      case ROUTES.DASHBOARD: this.activeTab = TABS.DASHBOARD
        break
      case ROUTES.GODOWN: this.activeTab = TABS.GODOWN
        break
      case ROUTES.WATCHLIST: this.activeTab = TABS.WATCHLIST
        break
      case ROUTES.UTILITIES: this.activeTab = TABS.UTILITIES
        break
    }
  }
constructor(private BMS: BusinessManagementService,private toaster: ToasterService){  
}
  router = inject(Router)
  route = inject(ActivatedRoute)
  tabs = TABS
  routes = ROUTES
  activeTab = <string>TABS.DASHBOARD
  
  setActiveTab(tabname: string, route: string) {
    if (this.isOrganisation){
      this.activeTab = tabname
      this.router.navigate([route]);
    } else {
     this.toaster.error('Please select GSTIN from above')
    }
  }
}