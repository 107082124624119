import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from '../../_models/Response';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  constructor(private http: HttpClient) {}

  isSchedulerInProgress(gstin: string): any {
    if (gstin) {
    return this.http
      .get(`${environment.apiUrl}/scheduler/progress?gstin=${gstin}`)
      .pipe(
        map((response: any) => {
          if (response.status === 1) {
            return response.response;
          }
        })
      );
    }
  }

  getLastUpdateDate(gstin: string): any {
    return this.http
      .get(`${environment.apiUrl}/scheduler/lastupdate?gstin=${gstin}`)
      .pipe(
        map((response: any) => {
          if (response.status === 1) {
            return response.response;
          }
        })
      );
  }
}
